import { Divider, } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Chip from '@mui/material/Chip/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GiftCodeStatusEnum, } from 'Apollo/graphql';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { useTranslation, } from 'react-i18next';
import { GoBackButton, } from '../utils';

interface Props {
  giftCodeStatus: GiftCodeStatusEnum;
  giftCode: string;
  expirationDate: Date | null;
  applyCode: () => void;
  resetStore: () => void;
}

const View = ({ giftCode, giftCodeStatus, applyCode, resetStore,expirationDate, }: Props): JSX.Element => {
  const { t, } = useTranslation('giftCodeSeller');
  const isExpired = expirationDate ? expirationDate > new Date() : false;
  return (
    <>
      <Typography variant="h1" sx={{ mb: 1.5, }}>
        {t('form.verificationTitle')}
      </Typography>
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('form.giftCodeNumber')}>{giftCode.replace(/^(.{3})(.{3})(.*)$/, '$1 $2 $3')}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} mb={3}>
          <NotEditableTextClassic label={t('form.giftCodeStatus')}>
            <Chip label={t(`states.${giftCodeStatus}`)} color={giftCodeStatus === GiftCodeStatusEnum.Applied ? 'default' : 'success'} />
          </NotEditableTextClassic>
        </Grid>
      </Grid>
      {giftCodeStatus !== GiftCodeStatusEnum.Applied && !isExpired &&  (
        <Button size="large" color="primary" sx={{ width: { xs: '100%', lg: 'unset', marginRight: '1rem', }, }} onClick={applyCode}>
          {t('form.btnApply')}
        </Button>
      )}
      <GoBackButton goBackFunction={resetStore} />
    </>
  );
};

export default View;
