import { useState, useCallback, useMemo, } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, } from '@mui/material/styles';
import AppLayout from 'Components/AppLayout/AppLayout';
import { PrizeWheelTypeEnum, usePrizeWheelQuery, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import MobileMenuContent from './MobileMenuContent';
import SidebarContent from '../SidebarContent';
import { SELLER_MENU_ITEMS, } from './constants';

const home = '/';

const LayoutSeller = (): JSX.Element => {
  const isMatchingLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  const [isOpenMobileMenu, setOpenMobileMenu,] = useState<boolean>(false);
  const handleOpenMobileMenu = useCallback(() => setOpenMobileMenu(true), []);
  const handleCloseMobileMenu = useCallback(() => setOpenMobileMenu(false), []);
  const authUser = useAuthUser();

  const { data: prizeWheelXmas2023, } = usePrizeWheelQuery({
    variables: {
      type: PrizeWheelTypeEnum.Xmas_2023,
    },
    fetchPolicy: 'network-only',
    skip: !authUser?.permissionsObject.GENERATE_PRIZE_WHEEL_XMAS_2023,
  });

  const menuItems = useMemo(()=>{
    return SELLER_MENU_ITEMS.filter((item) => {
      if (item.id === 'czGiftCodes') {
        return authUser?.permissionsObject.READ_CZ_GIFT_CODES && import.meta.env.VITE_APP_MARKET === 'CZ';
      }
    
      if (item.id === 'xmasWheel2023') {
        const hasPrize = prizeWheelXmas2023?.prizeWheel !== null;
        const hasPermission = authUser?.permissionsObject.GENERATE_PRIZE_WHEEL_XMAS_2023;

        if (hasPrize || !hasPermission) {
          return false;
        }
      }

      if (!authUser?.pointOfSell?.createsReplacements && authUser?.pointOfSell !== null && item.id === 'replacements') {
        return false;
      }

      return true;
    });
  }, [authUser?.permissionsObject.GENERATE_PRIZE_WHEEL_XMAS_2023, authUser?.permissionsObject.READ_CZ_GIFT_CODES, authUser?.pointOfSell, prizeWheelXmas2023?.prizeWheel,]);

  const mobileMenuContent = (
    <MobileMenuContent
      onClose={handleCloseMobileMenu}
    />
  );
  const sidebarContent = <SidebarContent menuItems={menuItems} sidebarHref={home} />;

  return (
    <AppLayout
      toHome={home}
      isMatchingLg={isMatchingLg}
      isOpenMobileMenu={isOpenMobileMenu}
      mobileMenuContent={mobileMenuContent}
      sidebarContent={sidebarContent}
      onCloseMobileMenu={handleCloseMobileMenu}
      onOpenMobileMenu={handleOpenMobileMenu}
    />
  );
};

export default LayoutSeller;
