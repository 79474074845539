import { Navigate, } from 'react-router-dom';
import { useAuthUser, } from 'Tools/auth';
import { AuthUserFragment, PermissionEnum, } from 'Apollo/graphql';
import { ADMIN_MENU_ITEMS, } from 'Components/Layouts/LayoutAdmin/constants';

const pickAdminRedirect = (authUser: AuthUserFragment | null) => {
  const possibleRoute = ADMIN_MENU_ITEMS.find((item) => {
    const permissions =
      item.permission === null
        ? []
        : Array.isArray(item.permission)
        ? item.permission
        : [item.permission,];

    return (
      permissions.length !== 0 &&
      permissions.some((permission) => {
        return (
          // for some reason permission here is typed as any
          authUser?.permissionsObject[permission as PermissionEnum] === true
        );
      }) &&
      item.id !== 'dashboard'
    );
  });
  return `${possibleRoute?.route}`;
};

const DashboardAdmin = (): JSX.Element | null => {
  const authUser = useAuthUser();

  return <Navigate to={pickAdminRedirect(authUser)} />;
};

export default DashboardAdmin;