import { ApolloError, useApolloClient, } from '@apollo/client';
import {
  AuthUserFragment,
  CreatePurchaseDocument,
  CreatePurchaseMutation,
  CreatePurchaseMutationVariables,
  GenderTitle,
  PreviouslyOwnedDevices,
  PurchasedAssortmentInput,
  PurchasedProductsForGlobalDb,
  PurchasedPromotionInput,
  RegisterCustomerDocument,
  RegisterCustomerMutation,
  RegisterCustomerMutationVariables,
  RegistrationType,
  RoleEnum,
} from 'Apollo/graphql';
import { StoreAssortment, StorePromotionAssortment, } from 'Modules/RegistrationSeller/store/helpers';
import {
  InformationVerificationFormValues,
  PromotionCategoryPurchaseObject,
  RegistrationStore,
} from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useCallback, useState, } from 'react';
import { useAuthUser, } from 'Tools/auth';

type MatRegisterVariablesArgs = {
  customer: RegistrationStore['customer'];
  customerRegistration: RegistrationStore['customerRegistration'];
  authUser: AuthUserFragment | null;
  values?: InformationVerificationFormValues;
  email: string;
};

export const mapRegisterVariables = ({ customerRegistration, authUser, values, email,}: MatRegisterVariablesArgs): RegisterCustomerMutationVariables => ({
  customerInput: {
    username: values?.username,
    acceptedPulzeCare: customerRegistration.acceptedPulzeCare,
    acceptedPulzeOn: customerRegistration.acceptedPulzeOn || false,
    acceptedTermsAndConditions: customerRegistration.acceptedTermsAndConditions,
    authorId: values?.user?.id || authUser?.id,
    chainId: authUser?.chainId,
    posId: values?.pos?.id || authUser?.pointOfSell?.id,
    dateOfBirth: customerRegistration.dateOfBirth,
    email,
    firstName: customerRegistration.firstName,
    lastName: customerRegistration.lastName,
    genderTitle: customerRegistration.title?.id as GenderTitle,
    phoneNumber: customerRegistration.phone,
    phonePrefix: customerRegistration.phonePrefix?.id as string,
    previouslyOwnedDevices: customerRegistration.previouslyOwnedDevices?.id as PreviouslyOwnedDevices,
    purchasedProductsForGlobalDB: customerRegistration.purchasedProducts?.id as PurchasedProductsForGlobalDb,
    // FIXME: this should not be there, should be handled by the backend
    registrationType: RegistrationType.Standard,
  },
});

const mapPromotionInput = (
  promotionCategoryPurchaseList: PromotionCategoryPurchaseObject,
  purchasedAssortmentsPromotionRaw: StorePromotionAssortment[],
): PurchasedPromotionInput[] => {
  const ret: PurchasedPromotionInput[] = [];
  const promotionCategoryKeyList = Object.keys(promotionCategoryPurchaseList);
  for (let i = 0;i < promotionCategoryKeyList.length; i++) {
    // one of the promotions duplicate ids
    const promotionCategoryId = promotionCategoryKeyList[i];
    const promotionCategoryByIdList = promotionCategoryPurchaseList[promotionCategoryKeyList[i]];
    for (let j = 0; j < promotionCategoryByIdList.length; j++) {
      const indexInCategory = j;
      const promotionToPurchase = promotionCategoryByIdList[j];
      const assortmentsWithDeviceCodesRaw = purchasedAssortmentsPromotionRaw.filter((assortment)=> assortment.promotionCategoryId===promotionCategoryId && assortment.indexInPromotions===indexInCategory);
      const { assortmentsWithoutDeviceCodes, } =promotionToPurchase;
      const assortmentsWithDeviceCodes: PurchasedAssortmentInput[] = assortmentsWithDeviceCodesRaw.map((assortment) => ({assortmentId:assortment.assortment.assortmentId,count:1,deviceCode:assortment.assortment.deviceCode,}));
      const assortmentsWithoutDeviceCodesMapped: PurchasedAssortmentInput[] = assortmentsWithoutDeviceCodes.map((assortment) => ({assortmentId:assortment.assortmentId,count:assortment.count,}));
      ret.push({ count: 1, promotionId:promotionCategoryId, purchasedAssortments: [...assortmentsWithDeviceCodes,...assortmentsWithoutDeviceCodesMapped,], });
    }
  }
  return ret;
};

export const mapAssortmentInput = (
  purchasePhase: RegistrationStore['purchase'],
  purchasedAssortmentsRaw: RegistrationStore['deviceCodeRequiredList'],
): PurchasedAssortmentInput[] => {
  const ret: PurchasedAssortmentInput[] = [];
  const assortmentWithDeviceCodes = purchasedAssortmentsRaw.map((assortment) => {
    return { assortmentId: assortment.assortmentId, count: 1, deviceCode: assortment.deviceCode, };
  });
  const categoryKeys = Object.keys(purchasePhase.assortmentCategories);
  for (let i = 0; i < categoryKeys.length; i++) {
    const category = purchasePhase.assortmentCategories[categoryKeys[i]];
    for (let j = 0; j < category.length; j++) {
      const {
        count,
        option: {
          id,
          assortmentCategory: { requiresDeviceCodes, },
        },
      } = category[j];
      if (count > 0 && !requiresDeviceCodes) ret.push({ count, assortmentId: id, });
    }
  }
  return [...ret, ...assortmentWithDeviceCodes,];
};

type MapCreatePurchaseVariablesArgs = {
  customerId: string | null;
  purchase: RegistrationStore['purchase'];
  authUser: AuthUserFragment;
  values: InformationVerificationFormValues;
  purchasedAssortmentsRaw: StoreAssortment[];
  purchasedAssortmentsPromotionRaw: StorePromotionAssortment[];
  promotionPurchase: PromotionCategoryPurchaseObject;
};

const mapCreatePurchaseVariables = ({
  customerId,
  purchase,
  authUser,
  values,
  purchasedAssortmentsRaw,
  purchasedAssortmentsPromotionRaw,
  promotionPurchase,
}: MapCreatePurchaseVariablesArgs): CreatePurchaseMutationVariables => {
  return {
    purchaseInput: {
      username: values.username,
      authorId: values.user?.id || authUser.id,
      chainId: authUser.chainId,
      customerId,
      posId: values.pos?.id || authUser.pointOfSell?.id,
      purchasedPromotions: mapPromotionInput(promotionPurchase, purchasedAssortmentsPromotionRaw),
      purchasedAssortments: mapAssortmentInput(purchase, purchasedAssortmentsRaw),
    },
  };
};

type UseMakePurchaseAgs = {
  customer: RegistrationStore['customer'];
  purchase: RegistrationStore['purchase'];
  customerRegistration: RegistrationStore['customerRegistration'];
  registerCustomer: RegistrationStore['registerCustomer'];
  completeInformationVerificationPhase: RegistrationStore['completeInformationVerificationPhase'];
  purchasedAssortmentsRaw: RegistrationStore['deviceCodeRequiredList'];
  promotionPurchase: PromotionCategoryPurchaseObject;
  purchasedAssortmentsPromotionRaw: RegistrationStore['deviceCodeRequiredPromotionsList'];
  isShortRegistration?: boolean;
};

type UseMakePurchaseReturn = {
  loading: boolean;
  error: ApolloError | Error | null;
  makePurchase: (values: InformationVerificationFormValues) => Promise<void>;
};

export const useMakePurchase = ({
  customer,
  purchase,
  customerRegistration,
  registerCustomer,
  completeInformationVerificationPhase,
  purchasedAssortmentsRaw,
  promotionPurchase,
  purchasedAssortmentsPromotionRaw,
  isShortRegistration,
}: UseMakePurchaseAgs): UseMakePurchaseReturn => {
  const authUser = useAuthUser();
  const apolloClient = useApolloClient();
  const [loading, setLoading,] = useState<boolean>(false);
  const [error, setError,] = useState<ApolloError | Error | null>(null);

  const handleMakePurchase = useCallback<UseMakePurchaseReturn['makePurchase']>(
    async (values) => {
      try {
        setLoading(true);
        setError(null);

        if (!authUser) throw new Error('no auth user');

        let customerId = customer?.id;

        if (!customerId && !isShortRegistration) {
          const registerResponse = await apolloClient.mutate<RegisterCustomerMutation, RegisterCustomerMutationVariables>({
            mutation: RegisterCustomerDocument,
            variables: mapRegisterVariables({
              customer,
              customerRegistration,
              authUser,
              values,
              email: customer?.email || "",
            }),
            fetchPolicy: 'no-cache',
          });

          if (!registerResponse.data?.registerCustomer.id) {
            throw new Error('response has no customer id');
          }

          registerCustomer({
            id: registerResponse.data.registerCustomer.id,
            email: registerResponse.data.registerCustomer.email,
            firstName: registerResponse.data.registerCustomer.firstName,
            lastName: registerResponse.data.registerCustomer.lastName,
            phoneNumber: registerResponse.data.registerCustomer.phoneNumber,
            phonePrefix: registerResponse.data.registerCustomer.phonePrefix,
            dateOfBirth: registerResponse.data.registerCustomer.dateOfBirth ? new Date(registerResponse.data.registerCustomer.dateOfBirth) : null,
            acceptedPulzeCare: registerResponse.data.registerCustomer.acceptedPulzeCare,
            acceptedPulzeOn: registerResponse.data.registerCustomer.acceptedPulzeOn,
            acceptedTermsAndConditions: registerResponse.data.registerCustomer.acceptedTermsAndConditions,
            previouslyOwnedDevices: registerResponse.data.registerCustomer.previouslyOwnedDevices.id,
            purchasedProductsForGlobalDB: registerResponse.data.registerCustomer.purchasedProductsForGlobalDB.id,
            // FIXME: this should not be there, should be handled by the backend
            registrationType: RegistrationType.Standard,
          });

          customerId = registerResponse.data.registerCustomer.id;
        }

        const onlyRegistration = authUser.role === RoleEnum.CallCenterAgentCz;

        if (!onlyRegistration) {
          if (!customerId && !isShortRegistration) {
            throw new Error('no customer ID');
          }

          const responseCreatePurchase = await apolloClient.mutate<CreatePurchaseMutation, CreatePurchaseMutationVariables>({
            mutation: CreatePurchaseDocument,
            variables: mapCreatePurchaseVariables({
              customerId: customerId || null,
              purchase,
              authUser,
              values,
              purchasedAssortmentsRaw,
              promotionPurchase,
              purchasedAssortmentsPromotionRaw,
            }),
            fetchPolicy: 'no-cache',
          });

          if (!responseCreatePurchase.data?.createPurchase) {
            throw new Error('no createPurchase');
          }

          completeInformationVerificationPhase(responseCreatePurchase.data.createPurchase, values);
        }

        completeInformationVerificationPhase(undefined, values);

        setLoading(false);
      } catch (catchError) {
        setError(catchError as Error);
        setLoading(false);
      }
    },
    [isShortRegistration, apolloClient, authUser, customer, purchase, customerRegistration, registerCustomer, completeInformationVerificationPhase,promotionPurchase,purchasedAssortmentsPromotionRaw,purchasedAssortmentsRaw,],
  );

  return {
    loading,
    error,
    makePurchase: handleMakePurchase,
  };
};
