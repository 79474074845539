import { useCallback, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { RoleEnum, useGetAllUserRolesQuery, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';
import { ApolloError, } from '@apollo/client';

export type UserRolesOption = {
  id: RoleEnum,
  labelKey: string,
};

type GetOptionLabel = (o: UserRolesOption) => string;
type GetOptionKey = (o: UserRolesOption) => string;

type UseOptionsUserRoles = () => {
  options: UserRolesOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<UserRolesOption>, 
  loading:boolean,
  error: ApolloError | undefined,
};

type UserRolesManageOption = {
  id: RoleEnum,
  labelKey: string,};

export const useOptionsUserRoles: UseOptionsUserRoles = () => {
  const { data, loading, error, } = useGetAllUserRolesQuery();

  const userRolesOptions: UserRolesManageOption[] = useMemo(
    () => {
      const options = data?.getAllRolesInfo.map(role => ({
        id: role.role,
        labelKey: `common:options.userRoles.${role.role}`,
      })) || [];
      return options;
    },
    [ data?.getAllRolesInfo, ]
  );

  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey, o.id),
    [ t, ],
  );
  return {
    options: userRolesOptions,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
    loading,
    error,
  };
};
