import { PermissionEnum, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/constants';
import AdministrationColoredIcon from 'Utils/svg/AdministrationColoredIcon';
import GiftCodesIcon from 'Utils/svg/GiftCodesIcon';
import POSColoredIcon from 'Utils/svg/POSColoredIcon';
import UsersColoredIcon from 'Utils/svg/UsersColoredIcon';
import ReplacementsColoredIcon from 'Utils/svg/ReplacementsColoredIcon';
import RegistrationsColoredIcon from 'Utils/svg/RegistrationsColoredIcon';
import TasksColoredIcon from 'Utils/svg/TasksColoredIcon';
import DashboardChainsIcon from 'Utils/svg/DashboardChainsIcon';
import DashboardPersonalDashboardIcon from 'Utils/svg/DashboardPersonalDashboardIcon';
import { MenuItem, } from '../types';

export const DASHBOARD_MENU_ITEMS: MenuItem[] = [
  {
    id: 'personalDashboard',
    exact: true,
    icon: <DashboardPersonalDashboardIcon sx={{ color: 'transparent',}} />,
    route: `/${ROUTES.dashboard}`,
    textKey: 'common:dashboardSidebar.personalDashboard',
    permission: PermissionEnum.AccessAdminApp,
    flag: null,
  },
  {
    id: 'users',
    exact: false,
    icon: <UsersColoredIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardUsers}`,
    textKey: 'common:dashboardSidebar.users',
    permission: [
      PermissionEnum.DashboardUsersActiveUsers,
      PermissionEnum.DashboardUsersTotalNumberOfUsers,
      PermissionEnum.DashboardUsersUserRoles,
    ],
    flag: null,
  },
  {
    id: 'chains',
    exact: false,
    icon: <DashboardChainsIcon sx={{ color: 'transparent',}} />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardChains}`,
    textKey: 'common:dashboardSidebar.chains',
    permission: [
      PermissionEnum.DashboardChainsActiveChains,
      PermissionEnum.DashboardChainsTop_10Registrations,
      PermissionEnum.DashboardChainsTop_10Replacements,
      PermissionEnum.DashboardChainsTotalNumberOfChains,
    ],
    flag: null,
  },
  {
    id: 'pos',
    exact: false,
    icon: <POSColoredIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardPos}`,
    textKey: 'common:dashboardSidebar.pos',
    permission: [
      PermissionEnum.DashboardPointsOfSaleTop_10Registrations,
      PermissionEnum.DashboardPointsOfSaleActivePos,
      PermissionEnum.DashboardPointsOfSaleTop_10Replacements,
      PermissionEnum.DashboardPointsOfSaleTotalNumberOfPos,
    ],
    flag: null,
  },
  {
    id: 'registrations',
    exact: false,
    icon: <RegistrationsColoredIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardRegistrations}`,
    textKey: 'common:dashboardSidebar.registrations',
    permission: [
      PermissionEnum.DashboardRegistrationsConsumerAgeGroups,
      PermissionEnum.DashboardRegistrationsConsumerTitleGender,
      PermissionEnum.DashboardRegistrationsMethodOfVerification,
      PermissionEnum.DashboardRegistrationsPreviouslyOwnedDevice,
      PermissionEnum.DashboardRegistrationsPurchasedProduct,
      PermissionEnum.DashboardRegistrationsReDoi,
      PermissionEnum.DashboardRegistrationsTop_10Cities,
      PermissionEnum.DashboardRegistrationsTotalNumberOfRegistrations,
      PermissionEnum.DashboardRegistrationsTotalNumberOfRePurchases,
    ],
    flag: null,
  },
  {
    id: 'replacements',
    exact: false,
    icon: <ReplacementsColoredIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardReplacements}`,
    textKey: 'common:dashboardSidebar.replacements',
    permission: [
      PermissionEnum.DashboardReplacementsTop_10ReasonsForReplacement,
      PermissionEnum.DashboardReplacementsTotalNumberOfReplacements,
    ],
    flag: null,
  },
  {
    id: 'tasksAndPoints',
    exact: false,
    icon: <TasksColoredIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardTasksAndPoints}`,
    textKey: 'common:dashboardSidebar.tasksAndPoints',
    permission: [
      PermissionEnum.DashboardPointsAndTasksNumberOfActiveTasks,
      PermissionEnum.DashboardPointsAndTasksNumberOfTasksCompletions,
      PermissionEnum.DashboardPointsAndTasksPointsCategory,
      PermissionEnum.DashboardPointsAndTasksTotalNumberOfPoints,
    ],
    flag: null,
  },
  {
    id: 'giftCodes',
    exact: false,
    icon: <GiftCodesIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardGiftCodes}`,
    textKey: 'common:dashboardSidebar.giftCodes',
    permission: [
      PermissionEnum.DashboardGiftCodesTotalNumberOfAvailableGiftCodes,
      PermissionEnum.DashboardGiftCodesTotalNumberOfSendGiftCodesByChain,
    ],
    flag: 'FEATURE_FLAG_ENABLE_GIFT_CODES_DASHBOARD',
  },
  {
    id: 'administration',
    exact: false,
    icon: <AdministrationColoredIcon />,
    route: `/${ROUTES.admin}`,
    textKey: 'common:sidebar.administration',
    permission: PermissionEnum.AccessAdminApp,
    flag: null,
  },
];
