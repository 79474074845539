import shallow from 'zustand/shallow';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useAuthUser, } from 'Tools/auth';
import AppContainer from 'Components/AppContainer';
import { PointOfSellStore, usePointOfSellStore, } from 'Modules/PointOfSell/store/PointOfSellStore';
import PointsOfSell from './PointsOfSell';

const selectStoreData = (s: PointOfSellStore) => ({
  openExportModal: s.openModalExportPointOfSell,
  openModalImport: s.openModalImportPos,
});

const PointOfSellTable = (): JSX.Element => {
  const { t, } = useTranslation('pos');
  const { openExportModal, openModalImport, } = usePointOfSellStore(selectStoreData, shallow);
  const authUser = useAuthUser();

  return (
    <AppContainer>
  
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h1">{t('pointsOfSell.title')}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {authUser?.permissionsObject.IMPORT_POS && (
              <Grid item>
                <Button variant="outlined" color="inherit" size="large" onClick={openModalImport}>
                  {t('pointsOfSell.btnImport')}
                </Button>
              </Grid>
            )}
            {authUser?.permissionsObject.ACCESS_ADMIN_EXPORT_DATA && <Grid item>
              <Button variant="outlined" color="inherit" size="large" onClick={openExportModal}>
                {t('pointsOfSell.btnExport')}
              </Button>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>

      <PointsOfSell />

    </AppContainer>
  );
};

export default PointOfSellTable;
