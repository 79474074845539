import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import AppContainer from 'Components/AppContainer';
import ModalCreateVoucher from 'Modules/Vouchers/modals/ModalCreateVoucher';
import ModalDetailVoucherPurchase from 'Modules/Vouchers/modals/ModalDetailPurchase';
import ModalDetailVoucher from 'Modules/Vouchers/modals/ModalDetailVoucher';
import ModalRemoveVoucher from 'Modules/Vouchers/modals/ModalRemoveVoucher';
import { useVoucherStore, VoucherStore, } from 'Modules/Vouchers/store/VoucherStore';
import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import PlusIcon from 'Utils/svg/PlusIcon';
import VoucherPurchases from './PurchaseTable/Purchases';
import Vouchers from './VoucherTable/Vouchers';

// const selectModalData = (s: VoucherStore) => s.modalCreateVoucher;
const selectOpen = (s: VoucherStore) => s.openModalCreateVoucher;


const VouchersAdminPage = (): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const [value, setValue,] = useState(0);
  const authUser = useAuthUser();

  // const { isOpen, } = useVoucherStore(selectModalData);
  const onOpen = useVoucherStore(selectOpen);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <AppContainer>
        <Grid container justifyContent="space-between" alignItems="center" spacing={2} paddingBottom={2}>
          <Grid item>
            <Typography variant="h1">{t('vouchers.title')}</Typography>
          </Grid>
          <Grid item>
            {authUser?.permissionsObject.MANAGE_VOUCHERS && <Button variant="contained" color="primary" size="large" startIcon={<PlusIcon />} onClick={onOpen}>
              {t('vouchers.btnCreate')}
            </Button>}
          </Grid>
        </Grid>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t('vouchers.title')} />
          <Tab label={t('purchases.title')} />
        </Tabs>
        {value === 0 ? <Vouchers /> : <VoucherPurchases />}
      </AppContainer>
      <ModalRemoveVoucher />
      <ModalDetailVoucher />
      <ModalCreateVoucher />
      <ModalDetailVoucherPurchase />
    </>
  );
};

export default VouchersAdminPage;
