import { useCurrentUserQuery, AuthUserFragment, } from 'Apollo/graphql';
import { AuthStore, useAuthStore, } from '../store/authStore';

const selectAccessToken = (s: AuthStore) => s.accessToken;

export const useAuthUser = (): AuthUserFragment | null => {
  const accessToken = useAuthStore(selectAccessToken);
  const { data, } = useCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });

  return accessToken
    ? data?.currentUser || null
    : null;
};
