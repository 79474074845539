import AppContainer from 'Components/AppContainer';
import { Grid, Typography, } from '@mui/material';
import TotalNumberOfRepurchases from 'Components/Dashboard/Registrations/TotalNumberOfRepurchases';
import TotalNumberOfRegistrations from 'Components/Dashboard/Registrations/TotalNumberOfRegistrations';
import MethodOfVerification from 'Components/Dashboard/Registrations/MethodOfVerification';
import ReDoi from 'Components/Dashboard/Registrations/ReDoi';
import GenderTitle from 'Components/Dashboard/Registrations/GenderTitle';
import PreviouslyOwnedDevice from 'Components/Dashboard/Registrations/PreviouslyOwnedDevice';
import RegistrationsByCities from 'Components/Dashboard/Registrations/RegistrationsByCities';
import SplitOfTotalRegistrations from 'Components/Dashboard/Registrations/SplitOfTotalRegistrations';
import PurchasedProduct from 'Components/Dashboard/Registrations/PurchasedProduct';
import { DashboardLayoutItemName, useUserDashboardLayoutQuery, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import { useTranslation, } from 'react-i18next';
import SplitOfTotalRegistrationsByRegistrationAge from 'Components/Dashboard/Registrations/SplitOfTotalRegistrationsByRegistrationAge';

const Registrations = () => {
  const { data, } = useUserDashboardLayoutQuery();
  const authUser = useAuthUser();
  const { t, } = useTranslation('common');
  return (
    <AppContainer>
      <Typography variant="h1" marginBottom={2}>{t('dashboardSidebar.registrations')}</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.permissionsObject
            .DASHBOARD_REGISTRATIONS_TOTAL_NUMBER_OF_REGISTRATIONS && (
            <Grid item xs={12}>
              <TotalNumberOfRegistrations
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsTotalNumberOfRegistrations,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.permissionsObject
            .DASHBOARD_REGISTRATIONS_TOTAL_NUMBER_OF_RE_PURCHASES && (
            <Grid item xs={12}>
              <TotalNumberOfRepurchases
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsTotalNumberOfRePurchases,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.permissionsObject
            .DASHBOARD_REGISTRATIONS_CONSUMER_TITLE_GENDER && (
            <Grid item xs={12}>
              <GenderTitle
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsConsumerTitleGender,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.permissionsObject
            .DASHBOARD_REGISTRATIONS_PREVIOUSLY_OWNED_DEVICE && (
            <Grid item xs={12}>
              <PreviouslyOwnedDevice
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsPreviouslyOwnedDevice,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.permissionsObject
            .DASHBOARD_REGISTRATIONS_TOP_10_CITIES && (
            <Grid item xs={12}>
              <RegistrationsByCities
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsTop_10Cities,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid xs={12} md={6} container item spacing={2} sx={{ height: '100%', }}>
          {authUser?.permissionsObject
            .DASHBOARD_REGISTRATIONS_METHOD_OF_VERIFICATION && (
            <Grid item xs={12}>
              <MethodOfVerification
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsMethodOfVerification,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_RE_DOI && (
            <Grid item xs={12}>
              <ReDoi
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsReDoi,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.permissionsObject
            .DASHBOARD_REGISTRATIONS_CONSUMER_AGE_GROUPS && (
            <Grid item xs={12}>
              <SplitOfTotalRegistrations
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsConsumerAgeGroups,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.permissionsObject
            .DASHBOARD_REGISTRATIONS_CONSUMER_AGE_GROUPS && (
            <Grid item xs={12}>
              <SplitOfTotalRegistrationsByRegistrationAge
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsConsumerAgeGroups,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.permissionsObject
            .DASHBOARD_REGISTRATIONS_PURCHASED_PRODUCT && (
            <Grid item xs={12}>
              <PurchasedProduct
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsPurchasedProduct,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AppContainer>
  );
};

export default Registrations;
