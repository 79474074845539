import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PointOfSellOptionFragment, usePointsOfSellListQuery, } from 'Apollo/graphql';
import InputAutocompleteClassic from 'Components/Inputs/InputAutocompleteClassic';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import { areOptionsEqualById, getOptionFieldName, renderOptionByIdName, } from 'Utils/helpers';
import RenderQr from './RenderQr';

interface Props {
  onClose: () => void;
}

const View = ({ onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const user = useAuthUser();

  const { loading, error, data, } = usePointsOfSellListQuery({
    fetchPolicy: 'cache-and-network',
    variables: { chainId: user?.chainId, },
  });
  const [posSelection, setPosSelection,] = useState<PointOfSellOptionFragment | null>(null);
  const handleChange = (e: unknown, newValue: PointOfSellOptionFragment | null) => {
    setPosSelection(newValue);
  };
  return (
    <ModalDrawerBody>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>{t('qrModal.description', { user: user?.username, })}</Typography>
        </Grid>
        <Grid item xs={12}>
          <InputAutocompleteClassic<PointOfSellOptionFragment, false, false, false>
            value={posSelection}
            onChange={handleChange}
            error={!!error}
            loading={loading}
            getOptionLabel={getOptionFieldName}
            renderOption={renderOptionByIdName}
            isOptionEqualToValue={areOptionsEqualById}
            options={data?.pointsOfSellList.data || []}
            placeholder={t('common:select.placeholder')}
          />
        </Grid>
        {posSelection?.id && (
          <Grid item xs={12}>
            <RenderQr posId={posSelection?.id} />
          </Grid>
        )}
      </Grid>
      <Box mt={4}>
        <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
          {t('common:actions.cancel')}
        </Button>
      </Box>
    </ModalDrawerBody>
  );
};

export default View;
