import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useGetQrCodeForPointOfSellQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import QRCode from 'react-qr-code';
import ClipboardCopy from '../../../../Components/ClipboardButton';

interface Props {
  posId: string;
}

const RenderQr = ({ posId, }: Props): JSX.Element => {
  const { loading, error, data, } = useGetQrCodeForPointOfSellQuery({
    fetchPolicy: 'no-cache',
    variables: { posId, },
  });
  if (loading) return <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress /></Box>;
  if (error || !data?.getQrCodeForPointOfSell) return <ErrorAlert error={error} forceError/>;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <QRCode value={data.getQrCodeForPointOfSell.url} />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ textDecoration: 'underline', wordBreak: 'break-all',}}>{data.getQrCodeForPointOfSell.url}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ClipboardCopy copyText={data.getQrCodeForPointOfSell.url} />
      </Grid>
    </Grid>
  );
};

export default RenderQr;
