import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import AppContainer from 'Components/AppContainer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useAuthUser } from 'Tools/auth';
import { PointsTransactionStore, UsePointsTransactionStore } from 'Modules/PointsTransactionAdmin/store/PointsTransactionStore';
import shallow from 'zustand/shallow';
import PointsTransactions from './PointsTransactions';

const selectStoreData = (s: PointsTransactionStore) => ({
  openModalExportPointsTransaction: s.openModalExportPointsTransaction,
  openModalCreatePointsTransaction: s.openModalCreatePointsTransaction,
});

const PointsTransactionsTable = (): JSX.Element => {
  const { t } = useTranslation('pointsTransactionAdmin');

  const { openModalExportPointsTransaction, openModalCreatePointsTransaction } = UsePointsTransactionStore(selectStoreData, shallow);

  const authUser = useAuthUser();

  return (
    <AppContainer>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <Typography variant="h1">{t('pointsTransactions.title')}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {authUser?.permissionsObject.CREATE_POINTS_TRANSACTION && (
              <Grid item>
                <Button variant="outlined" color="inherit" size="large" onClick={openModalCreatePointsTransaction}>
                  {t('pointsTransactions.btnCreate')}
                </Button>
              </Grid>
            )}
            {authUser?.permissionsObject.ACCESS_ADMIN_EXPORT_DATA && (
              <Grid item>
                <Button variant="outlined" color="inherit" size="large" onClick={openModalExportPointsTransaction}>
                  {t('pointsTransactions.btnExport')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <PointsTransactions />
    </AppContainer>
  );
};

export default PointsTransactionsTable;
