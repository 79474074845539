import { useEffect, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useFormContext, useWatch, } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { ChainFragment, PointOfSellOptionFragment, useChainsQuery, } from 'Apollo/graphql';
import { FormInputText, FormInputAutocomplete, FormInputPhoneNumber, } from 'Components/Form';
import { areOptionsEqualById, renderOptionById, getOptionFieldId, } from 'Utils/helpers';
import { useOptionsUserRolesManage, UserRolesManageOption, } from 'Utils/options/useOptionsUserRolesManage';
import { useSearchOptionsPos, } from 'Utils/options/useSearchOptionsPos';
import { useAuthUser, } from 'Tools/auth';

const UserCreateForm = (): JSX.Element => {
  const { t, } = useTranslation('users');
  const authUser = useAuthUser();

  const { control, setValue, } = useFormContext();
  const role = useWatch({ name: 'role', control, }) as UserRolesManageOption | null;
  const chainId = useWatch({ name: 'chainId', control, }) as ChainFragment | null;

  const userRolesOptions = useOptionsUserRolesManage();
  const chainsResult = useChainsQuery({ fetchPolicy: 'cache-and-network', });
  const posOptions = useSearchOptionsPos(chainId?.id);

  useEffect(
    () => {
      setValue('posId', null);
      setValue('posIds', []);
    },
    [ chainId, setValue, ],
  );

  useEffect(
    () => {
      if (!role?.requiresChainId) setValue('chainId', null);
      if (!role?.requiresPosId) setValue('posId', null);
      if (!role?.requiresPosIds) setValue('posIds', []);
    },
    [ role, setValue, ],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormInputAutocomplete<UserRolesManageOption>
          name="role"
          triggerFields={['chainId', 'posId', 'posIds',]}
          getOptionLabel={userRolesOptions.getOptionLabel}
          isOptionEqualToValue={userRolesOptions.isOptionEqualToValue}
          options={userRolesOptions.options}
          renderOption={renderOptionById(userRolesOptions.getOptionLabel)}
          label={t('user.labelRole')}
          placeholder={t('common:select.placeholder')}
          loading={userRolesOptions.loading}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputAutocomplete<ChainFragment>
          name="chainId"
          getOptionLabel={getOptionFieldId}
          isOptionEqualToValue={areOptionsEqualById}
          renderOption={renderOptionById(getOptionFieldId)}
          options={chainsResult.data?.chains || []}
          loading={chainsResult.loading}
          label={t('user.labelChainId')}
          placeholder={t('common:select.placeholder')}
          required={role?.requiresChainId}
          disabled={!role?.requiresChainId}
        />
      </Grid>
      {role?.requiresPosId && (
        <Grid item xs={12}>
          <FormInputAutocomplete<PointOfSellOptionFragment>
            name="posId"
            options={posOptions.options}
            getOptionLabel={posOptions.getOptionLabel}
            isOptionEqualToValue={posOptions.isOptionEqualToValue}
            renderOption={renderOptionById(posOptions.getOptionLabel)}
            filterOptions={posOptions.filterOptions}
            loading={posOptions.loading}
            onInputChange={posOptions.onInputChange}
            noOptionsText={posOptions.noOptionsText}
            label={t('user.labelPosId')}
            placeholder={t('common:select.placeholder')}
            required={role?.requiresPosId}
            disabled={!chainId || !role?.requiresPosId}
          />
        </Grid>
      )}
      {authUser?.featureFlagsObject.FEATURE_FLAG_SHOW_POS_IDS_IN_USER_DASHBOARD && role?.requiresPosIds &&(
        <Grid item xs={12}>
          <FormInputAutocomplete<PointOfSellOptionFragment, true>
            name="posIds"
            multiple
            options={posOptions.options}
            getOptionLabel={posOptions.getOptionLabel}
            isOptionEqualToValue={posOptions.isOptionEqualToValue}
            renderOption={renderOptionById(posOptions.getOptionLabel)}
            filterOptions={posOptions.filterOptions}
            loading={posOptions.loading}
            onInputChange={posOptions.onInputChange}
            noOptionsText={posOptions.noOptionsText}
            label={t('user.labelPosIds')}
            placeholder={t('common:select.placeholder')}
            required={role?.requiresPosIds}
            disabled={!chainId || !role?.requiresPosIds}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInputText name="username" label={t('user.labelUsername')} required autoComplete="new-user" id="new-user" />
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="password" type="password" label={t('user.labelPassword')} required autoComplete="new-password" id="new-password" />
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="firstName" label={t('user.labelFirstName')} required autoComplete="new-firstName" id="new-firstName" />
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="lastName" label={t('user.labelLastName')} required autoComplete="new-lastName" id="new-lastName" />
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="email" label={t('user.labelEmail')} required autoComplete="new-email" id="new-email" />
      </Grid>
      <Grid item xs={12}>
        <FormInputPhoneNumber namePrefix="phonePrefix" namePhone="phoneNumber" label={t('user.labelPhone')} required id="nope" />
      </Grid>
    </Grid>
  );
};

export default UserCreateForm;
