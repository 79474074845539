import { MouseEventHandler, } from 'react';
import { useTranslation, } from 'react-i18next';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import { AssortmentFragment, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellImage from 'Components/Table/TableCellImage';
import TableCellText from 'Components/Table/TableCellText';

interface Props {
  row: AssortmentFragment
  onEdit: (assortment: AssortmentFragment) => void,
  onRemove: (assortment: AssortmentFragment) => void,
};

const AssortmentsTableRow = ({
  row,
  onEdit,
  onRemove,
}: Props): JSX.Element => {
  const { t, } = useTranslation('assortmentAdmin');
  const authUser = useAuthUser();

  const handleRemove: MouseEventHandler<HTMLLIElement> = () => {
    onRemove(row);
  };

  const handleEdit: MouseEventHandler<HTMLLIElement> = () => {
    onEdit(row);
  };

  return (
    <TableRow key={row.id}>
      <TableCellText>
        {row.id}
      </TableCellText>
      <TableCellText>
        {row.assortmentCategory.name}
      </TableCellText>
      <TableCellText>
        {row.name}
      </TableCellText>
      <TableCellImage
        src={row.image?.publicUrl}
      />
      <TableCellText>
        {row.description || ''}
      </TableCellText>
      {authUser?.permissionsObject.MANAGE_ASSORTMENT ? (
        <TableCellActions>
          <MenuItem
            onClick={handleEdit}
          >
            {t('assortments.table.btnDetail')}
          </MenuItem>
          <MenuItem
            onClick={handleRemove}
          >
            {t('assortments.table.btnRemove')}
          </MenuItem>
        </TableCellActions>
      ) : (
        <TableCell padding="none" />
      )}
    </TableRow>
  );
};

export default AssortmentsTableRow;
