import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import { PointsTransactionFragment } from 'Apollo/graphql';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellText from 'Components/Table/TableCellText';
import { useAuthUser } from 'Tools/auth';
import { useOptionsPointsTransactionReason } from 'Utils/options/usePointsTransactionReason';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { evaluateOperationSymbol } from './utils';

interface Props {
  row: PointsTransactionFragment;
  onDetail: (pointsTransaction: PointsTransactionFragment) => void;
  onRemove: (pointsTransaction: PointsTransactionFragment) => void;
  onEdit: (pointsTransaction: PointsTransactionFragment) => void;
}

const PointsTransactionsTableRow = ({ row, onDetail, onRemove, onEdit }: Props): JSX.Element => {
  const { t } = useTranslation('pointsTransactionAdmin');
  const authUser = useAuthUser();

  const handleRemove: MouseEventHandler<HTMLLIElement> = () => {
    onRemove(row);
  };

  const handleDetail: MouseEventHandler<HTMLLIElement> = () => {
    onDetail(row);
  };

  const handleEdit: MouseEventHandler<HTMLLIElement> = () => {
    onEdit(row);
  };

  const pointsTransactionReasonOptions = useOptionsPointsTransactionReason();

  return (
    <TableRow key={row.id}>
      <TableCellText>{row.posId}</TableCellText>
      <TableCellText>{row.author.username}</TableCellText>
      <TableCellText>{row.chainId}</TableCellText>
      <TableCellText>{row.id}</TableCellText>
      <TableCellText>{row.reason.name}</TableCellText>
      <TableCellText>{row.createdAt ? t('common:date.fullDate', { date: new Date(row.createdAt) }) : '-'}</TableCellText>
      <TableCellText>{`${evaluateOperationSymbol(row.operation)} ${row.amount}`}</TableCellText>
      <TableCellActions>
        <MenuItem onClick={handleEdit}>{t('pointsTransactions.table.btnEdit')}</MenuItem>
        <MenuItem onClick={handleDetail}>{t('pointsTransactions.table.btnDetail')}</MenuItem>
        {authUser?.permissionsObject.MANAGE_POINTS && <MenuItem onClick={handleRemove}>{t('pointsTransactions.table.btnRemove')}</MenuItem>}
      </TableCellActions>
    </TableRow>
  );
};

export default PointsTransactionsTableRow;
