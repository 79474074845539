import { useTranslation, } from 'react-i18next';
import { Link as LinkRouter, } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import { GeneralTaskFragment, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/constants';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellText from 'Components/Table/TableCellText';
import { useAuthUser, } from 'Tools/auth';
import TableCellBlock from 'Components/Table/TableCellBlock';
import { valueOrEmpty, } from 'Utils/parsers';
import { MouseEventHandler, } from 'react';
import IsNowAvailableChip from './IsNowAvailableChip';

interface Props {
  row: GeneralTaskFragment,
  onDelete: (task: GeneralTaskFragment) => void,
  onExport: (task: GeneralTaskFragment) => void,
}
const TasksTableRow = ({
  row,
  onDelete,
  onExport,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const authUser = useAuthUser();

  const handleDelete: MouseEventHandler<HTMLLIElement> = () => onDelete(row);
  const handleExport: MouseEventHandler<HTMLLIElement> = () => onExport(row);

  return (
    <TableRow key={row.id}>
      <TableCellText>{valueOrEmpty(row.id)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.type)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.name)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.pointsForCompletion)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.pointsLimit)}</TableCellText>
      <TableCellText>{t('common:date.fullDate', { date: new Date(row.validFrom), })}</TableCellText>
      <TableCellText>{t('common:date.fullDate', { date: new Date(row.validTo), })}</TableCellText>
      <TableCellText>{valueOrEmpty(row.typeOfCompletion)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.assessmentMethod)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.renewal)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.attemptLimit)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.author.username)}</TableCellText>
      <TableCellBlock><IsNowAvailableChip isAvailable={row.isNowAvailable} /></TableCellBlock>
      <TableCellText>{valueOrEmpty(row.chainIds.join(', ') || '-')}</TableCellText>
      <TableCellText>{valueOrEmpty(row.taskCompletionsCount)}</TableCellText>
      <TableCellActions>
        <MenuItem
          component={LinkRouter}
          to={`/${ROUTES.admin}/${ROUTES.tasks}/${row.id}`}
        >
          {t('tasks.table.btnDetail')}
        </MenuItem>
        {authUser?.permissionsObject.MANAGE_TASKS && (
          <MenuItem onClick={handleDelete}>{t('tasks.table.btnDelete')}</MenuItem>
        )}
        {authUser?.permissionsObject.MANAGE_TASKS && (
          <MenuItem onClick={handleExport}>{t('tasks.table.btnExport')}</MenuItem>
        )}
      </TableCellActions>
    </TableRow>
  );
};

export default TasksTableRow;
