import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ExportTypesEnum, useExportBrandAmbassadorsMutation } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import ExportTypeForm from 'Components/Export/ExportTypeForm';
import { exportTypeSchema } from 'Components/Export/helpers';
import LoadingBox from 'Components/LoadingBox';
import { EXPORT_TYPE_OPTIONS } from 'Utils/options/useOptionsExportTypes';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ExportQrCodes = (): JSX.Element => {
  const { t } = useTranslation('registrationsAdmin');
  const [getQrQuery, qrData] = useExportBrandAmbassadorsMutation({
    fetchPolicy: 'no-cache',
  });

  const [chosenType, setChosenType] = useState<ExportTypesEnum | null>(null);

  const methods = useForm({
    defaultValues: { type: EXPORT_TYPE_OPTIONS[0] },
    resolver: yupResolver(exportTypeSchema),
    mode: 'onTouched',
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    setChosenType(values.type.id);
  });

  useEffect(() => {
    if (chosenType) {
      getQrQuery({ variables: { type: chosenType } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenType]);

  return (
    <Grid item>
      {qrData.error && chosenType && (
        <Box mt={4}>
          <ErrorAlert error={qrData.error} />
        </Box>
      )}
      {qrData.loading && chosenType && (
        <Box mt={4}>
          <LoadingBox />
        </Box>
      )}
      {qrData.data && chosenType && (
        <Box mt={4}>
          <Button component="a" href={qrData.data?.exportBaQrCodes.file.publicUrl} download size="large" variant="contained" color="primary">
            {t('common:actions.download')}
          </Button>
        </Box>
      )}
      {!chosenType && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <ExportTypeForm />
          </form>
        </FormProvider>
      )}
    </Grid>
  );
};

export default ExportQrCodes;
