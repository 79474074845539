import { Routes, Route, } from 'react-router-dom';
import { ROUTES, } from 'Utils/constants';
import { useAuthInitUser, useAuthUser, } from 'Tools/auth';
import ProtectedRoute from 'Components/ProtectedRoute';
import LayoutSeller from 'Components/Layouts/LayoutSeller';
import LayoutAdmin from 'Components/Layouts/LayoutAdmin';
import LoginPage from 'Modules/Auth/pages/LoginPage';
import DashboardAdminPage from 'Modules/DashboardAdmin/pages/DashboardAdminPage';
import DashboardSellerPage from 'Modules/DashboardSeller/pages/DashboardSellerPage';
import AppLayoutNoUser from 'Components/AppLayout/AppLayoutNoUser';
import AssortmentSellerPage from 'Modules/AssortmentSeller/pages/AssortmentSellerPage';
import AssortmentAdminPage from 'Modules/AssortmentAdmin/pages/AssortmentAdminPage';
import PointsTransactionAdminPage from 'Modules/PointsTransactionAdmin/pages/PointsTransactionAdminPage';
import RegistrationSellerPage from 'Modules/RegistrationSeller/pages/RegistrationSellerPage';
import PromotionsAdminPage from 'Modules/PromotionsAdmin/pages/PromotionsAdminPage';
import UsersPage from 'Modules/Users/pages/UsersPage';
import PointOfSellPage from 'Modules/PointOfSell/pages/PointOfSellPage';
import ReplacementSellerPage from 'Modules/ReplacementSeller/pages/ReplacementSellerPage';
import ReplacementAdminPage from 'Modules/ReplacementAdmin/pages/ReplacementAdminPage';
import RegistrationAdminPage from 'Modules/RegistrationsAdmin/pages/RegistrationAdminPage';
import ForgottenPasswordPage from 'Modules/Auth/pages/ForgottenPasswordPage';
import ResetPasswordPage from 'Modules/Auth/pages/ResetPasswordPage';
import LoginQrCodePage from 'Modules/Auth/pages/LoginQrCodePage';
import PromotionsSellerPage from 'Modules/PromotionsSeller';
import NewsArticlesAdminPage from 'Modules/NewsArticlesAdmin/pages/NewsArticles';
import SupportSeller from 'Modules/SupportSeller/pages';
import NewsArticlesListPage from 'Modules/NewsArticlesSeller/pages/NewsArticlesListPage';
import NewsArticleDetailPage from 'Modules/NewsArticlesSeller/pages/NewsArticleDetailPage';
import TasksSellerPage from 'Modules/TasksSeller/pages/TasksSellerPage';
import TaskDetailPage from 'Modules/TasksAdmin/pages/TaskDetailPage';
import TasksAdminPage from 'Modules/TasksAdmin/pages/TasksTable';
import FillTaskPage from 'Modules/TasksSeller/pages/FillTaskPage';
import CreateTaskPage from 'Modules/TasksAdmin/pages/CreateTaskPage';
import TransactionList from 'Modules/MyPointsSeller/pages/TransactionList';
import MyPointsSeller from 'Modules/MyPointsSeller/pages/MyPoints';
import TaskReviewPage from 'Modules/TasksAdmin/pages/TaskReviewPage';
import MyProfile from 'Modules/MyProfile/pages/MyProfile';
import EditMyProfile from 'Modules/MyProfile/pages/EditMyProfile';
import VouchersAdminPage from 'Modules/Vouchers/pages/RegistrationAdminPage';
import VoucherDetail from 'Modules/MyPointsSeller/pages/VoucherDetail';
import DeleteMyProfile from 'Modules/MyProfile/pages/DeleteMyProfile';
import GiftCodePage from 'Modules/GiftCodeSeller/pages/GiftCodePage';
import { useTranslation, } from 'react-i18next';
import EditMyTaxInformation from 'Modules/MyProfile/pages/EditMyTaxInformation';
import CzGiftCodePage from 'Modules/GiftCodeSeller/pages/CzGiftCodePage';
import ChristmasPrizeWheelPage from 'Modules/PrizeWheel/pages/ChristmasWheel';
import LayoutDashboard from 'Components/Layouts/LayoutDashboard';
import Users from 'Modules/Dashboards/pages/Users';
import Pos from 'Modules/Dashboards/pages/Pos';
import TasksAndPoints from 'Modules/Dashboards/pages/TasksAndPoints';
import Registrations from 'Modules/Dashboards/pages/Registrations';
import Replacements from 'Modules/Dashboards/pages/Replacements';
import Chains from 'Modules/Dashboards/pages/Chains';
import UsersByRolesPage from 'Modules/Dashboards/pages/Users/UsersByRoles/UsersByRoles';
import TopPosInRegistrationsPage from 'Modules/Dashboards/pages/Pos/TopPosInRegistrations';
import ActiveInactivePosPage from 'Modules/Dashboards/pages/Pos/ActiveInactivePos';
import TopPosInReplacementsPage from 'Modules/Dashboards/pages/Pos/TopPosInReplacements';
import TotalReplacementsPage from 'Modules/Dashboards/pages/Replacements/TotalReplacements';
import TopReasonsForReplacementsPage from 'Modules/Dashboards/pages/Replacements/TopReasonsForReplacements';
import PointsCategoriesPage from 'Modules/Dashboards/pages/TasksAndPoints/PointsCategories';
import TotalPointsPage from 'Modules/Dashboards/pages/TasksAndPoints/TotalPoints';
import ActiveInactiveChainsPage from 'Modules/Dashboards/pages/Chains/ActiveInactiveChains';
import TopChainsInRegistrationsPage from 'Modules/Dashboards/pages/Chains/TopChainsInRegistrations';
import TopChainsInReplacementsPage from 'Modules/Dashboards/pages/Chains/TopChainsInReplacements';
import ActiveInactiveUsersPage from 'Modules/Dashboards/pages/Users/ActiveInactiveUsers';
import TotalRegistrationsPage from 'Modules/Dashboards/pages/Registrations/TotalRegistrations';
import MethodOfVerificationPage from 'Modules/Dashboards/pages/Registrations/MethodOfVerification';
import ReDOIPage from 'Modules/Dashboards/pages/Registrations/ReDOI';
import RepurchasesPage from 'Modules/Dashboards/pages/Registrations/Repurchases';
import GenderTitlePage from 'Modules/Dashboards/pages/Registrations/GenderTitle';
import SplitOfTotalRegistrationsPage from 'Modules/Dashboards/pages/Registrations/SplitOfTotalRegistrations';
import PreviouslyOwnedDevicePage from 'Modules/Dashboards/pages/Registrations/PreviouslyOwnedDevice';
import PurchasedProductsPage from 'Modules/Dashboards/pages/Registrations/PurchasedProducts';
import RegistrationsByCitiesPage from 'Modules/Dashboards/pages/Registrations/RegistrationsByCities';
import NumberOfTaskCompletionsPage from 'Modules/Dashboards/pages/TasksAndPoints/NumberOfCompletedTasks';
import NumberOfActiveTasksPage from 'Modules/Dashboards/pages/TasksAndPoints/NumberOfActiveTasks';
import TotalNumberOfAvailableGiftCodesPage from 'Modules/Dashboards/pages/ImportedPlGiftCodes/TotalNumberOfAvailableGiftCodes';
import TotalNumberOfSentGiftCodesPage from 'Modules/Dashboards/pages/ImportedPlGiftCodes/TotalNumberOfSentGiftCodes';
import ImportedPlGiftCodes from 'Modules/Dashboards/pages/ImportedPlGiftCodes';
import PersonalizedDashboard from 'Modules/Dashboards/pages/PersonalizedDashboard';
import SplitOfTotalRegistrationsByRegistrationAgePage from 'Modules/Dashboards/pages/Registrations/SplitOfTotalRegistrationsByRegistrationAge';
import LoadingScreen from './LoadingScreen';
import AcceptTermsAndConditionsModal from './AcceptTermsAndConditions/AcceptTermsAndConditionsModal';
import NotificationPopUpModal from './NotificationPopUp/NotificationPopUpModal';

const Core = (): JSX.Element => {
  const isLoading = useAuthInitUser();
  const authUser = useAuthUser();
  const {
    i18n: { language, },
  } = useTranslation('common');
  if (isLoading) return <LoadingScreen />;

  return (
    <>
      <NotificationPopUpModal />
      <AcceptTermsAndConditionsModal />
      <Routes>
        <Route element={<AppLayoutNoUser />}>
          <Route path={ROUTES.login}>
            <Route index element={<LoginPage />} />
            <Route path=":code" element={<LoginQrCodePage />} />
          </Route>
          <Route path={ROUTES.forgottenPassword} element={<ForgottenPasswordPage />} />
          <Route path={`${ROUTES.resetPassword}/:resetCode`} element={<ResetPasswordPage />} />
        </Route>

        <Route
          element={
            <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_SELLER_APP}>
              <LayoutSeller />
            </ProtectedRoute>
          }
        >
          <Route index element={<DashboardSellerPage />} />
          <Route path="*" element={<DashboardSellerPage />} />
          <Route
            path={ROUTES.assortment}
            element={
              <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_SELLER_APP_ASSORTMENT}>
                <AssortmentSellerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.promotions}
            element={
              <ProtectedRoute
                isAllowed={authUser?.permissionsObject.ACCESS_SELLER_APP_PROMOTIONS && authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PROMOTIONS}
              >
                <PromotionsSellerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.prizeWheelXmas2023}
            element={
              <ProtectedRoute isAllowed={authUser?.permissionsObject.GENERATE_PRIZE_WHEEL_XMAS_2023}>
                <ChristmasPrizeWheelPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.registration}
            element={
              <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_SELLER_APP_REGISTRATIONS}>
                <RegistrationSellerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.shortRegistration}
            element={
              <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_SELLER_APP_SHORT_REGISTRATIONS_PL || authUser?.role === 'SUPER_ADMIN'}>
                <RegistrationSellerPage isShortRegistration />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.myPoints}
            element={
              <ProtectedRoute
                isAllowed={authUser?.permissionsObject.ACCESS_SELLER_APP_MY_POINTS && authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_POINTS}
              />
            }
          >
            <Route index element={<MyPointsSeller />} />
            <Route path="*" element={<MyPointsSeller />} />
            <Route
              path={ROUTES.transactions}
              element={
                <ProtectedRoute
                  isAllowed={authUser?.permissionsObject.ACCESS_SELLER_APP_MY_POINTS && authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_POINTS}
                >
                  <TransactionList />
                </ProtectedRoute>
              }
            />
            <Route path=":voucherId" element={<VoucherDetail />} />
          </Route>
          <Route
            path={ROUTES.sellerSupport}
            element={
              <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_SELLER_APP_SUPPORT}>
                <SupportSeller />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.replacement}
            element={
              <ProtectedRoute
                isAllowed={
                  authUser?.permissionsObject.ACCESS_SELLER_APP_REPLACEMENTS && authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_REPLACEMENTS
                }
              >
                <ReplacementSellerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.giftCodeSeller}
            element={
              <ProtectedRoute
                isAllowed={authUser?.permissionsObject.READ_PL_GIFT_CODES && authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_SELF_REGISTRATION}
              >
                <GiftCodePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.czGiftCodeSeller}
            element={
              <ProtectedRoute isAllowed={authUser?.permissionsObject.READ_CZ_GIFT_CODES}>
                <CzGiftCodePage />
              </ProtectedRoute>
            }
          />
          <Route path={ROUTES.tasks} element={<ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_SELLER_APP_TASKS} />}>
            <Route index element={<TasksSellerPage />} />
            <Route path="*" element={<TasksSellerPage />} />
            <Route path=":taskId" element={<FillTaskPage />} />
          </Route>
          <Route path={ROUTES.news} element={<ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_SELLER_APP_NEWS} />}>
            <Route index element={<NewsArticlesListPage />} />
            <Route path="*" element={<NewsArticlesListPage />} />
            <Route path=":id" element={<NewsArticleDetailPage />} />
          </Route>
          <Route path={ROUTES.myProfile} element={<ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_SELLER_APP_MY_PROFILE} />}>
            <Route index element={<MyProfile />} />
            <Route path="*" element={<MyProfile />} />
            <Route
              path={ROUTES.editMyTaxInfo}
              element={
                <ProtectedRoute
                  isAllowed={
                    authUser?.permissionsObject.ACCESS_SELLER_APP_MY_TAX_INFO && authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_TAX_INFORMATION
                  }
                >
                  <EditMyTaxInformation />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.editMyProfile}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.MANAGE_MY_PROFILE}>
                  <EditMyProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.deleteMyProfile}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.MANAGE_MY_PROFILE}>
                  <DeleteMyProfile />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>

        <Route path={ROUTES.admin}>
          <Route
            element={
              <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_ADMIN_APP}>
                <LayoutAdmin />
              </ProtectedRoute>
            }
          >
            <Route index element={<DashboardAdminPage />} />
            <Route
              path={ROUTES.assortment}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_ADMIN_ASSORTMENT_VIEW}>
                  <AssortmentAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.promotions}
              element={
                <ProtectedRoute
                  isAllowed={authUser?.permissionsObject.ACCESS_ADMIN_PROMOTIONS_VIEW && authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PROMOTIONS}
                >
                  <PromotionsAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.users}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_ADMIN_USERS_VIEW}>
                  <UsersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.pointsOfSell}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_ADMIN_POS_VIEW}>
                  <PointOfSellPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.pointsTransaction}
              element={
                <ProtectedRoute
                  isAllowed={authUser?.permissionsObject.ACCESS_ADMIN_POINTS_VIEW && authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_POINTS}
                >
                  <PointsTransactionAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.replacement}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_ADMIN_REPLACEMENTS_VIEW}>
                  <ReplacementAdminPage />
                </ProtectedRoute>
              }
            />
            <Route path={ROUTES.tasks} element={<ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_ADMIN_TASKS_VIEW} />}>
              <Route index element={<TasksAdminPage />} />
              <Route path="*" element={<TasksAdminPage />} />
              <Route
                path={ROUTES.create}
                element={
                  <ProtectedRoute isAllowed={authUser?.permissionsObject.MANAGE_TASKS}>
                    <CreateTaskPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.review}/:taskId`}
                element={
                  <ProtectedRoute isAllowed={authUser?.permissionsObject.REVIEW_TASKS}>
                    <TaskReviewPage />
                  </ProtectedRoute>
                }
              />
              <Route path=":taskId" element={<TaskDetailPage />} />
            </Route>
            <Route
              path={ROUTES.news}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_ADMIN_NEWS_VIEW}>
                  <NewsArticlesAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.registration}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_ADMIN_REGISTRATIONS_VIEW}>
                  <RegistrationAdminPage />
                </ProtectedRoute>
              }
            />
            {language !== 'hu' && (
              <Route
                path={ROUTES.vouchers}
                element={
                  <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_ADMIN_VOUCHERS_VIEW}>
                    <VouchersAdminPage />
                  </ProtectedRoute>
                }
              />
            )}
            <Route path="*" element={<DashboardAdminPage />} />
          </Route>
        </Route>

        <Route path={ROUTES.dashboard}>
          <Route
            element={
              <ProtectedRoute isAllowed={authUser?.permissionsObject.ACCESS_DASHBOARDS_APP}>
                <LayoutDashboard />
              </ProtectedRoute>
            }
          >
            <Route index element={<PersonalizedDashboard />} />
            <Route
              path={ROUTES.dashboardUsers}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_USERS_ACTIVE_USERS ||authUser?.permissionsObject.DASHBOARD_USERS_TOTAL_NUMBER_OF_USERS || authUser?.permissionsObject.DASHBOARD_USERS_USER_ROLES}>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardPos}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_POINTS_OF_SALE_TOP_10_REGISTRATIONS || authUser?.permissionsObject.DASHBOARD_POINTS_OF_SALE_ACTIVE_POS || authUser?.permissionsObject.DASHBOARD_POINTS_OF_SALE_TOP_10_REPLACEMENTS || authUser?.permissionsObject.DASHBOARD_POINTS_OF_SALE_TOTAL_NUMBER_OF_POS}>
                  <Pos />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardRegistrations}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_CONSUMER_AGE_GROUPS || authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_CONSUMER_TITLE_GENDER || authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_METHOD_OF_VERIFICATION || authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_PREVIOUSLY_OWNED_DEVICE || authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_PURCHASED_PRODUCT || authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_RE_DOI || authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_TOP_10_CITIES || authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_TOTAL_NUMBER_OF_REGISTRATIONS || authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_TOTAL_NUMBER_OF_RE_PURCHASES}>
                  <Registrations />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardReplacements}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REPLACEMENTS_TOP_10_REASONS_FOR_REPLACEMENT || authUser?.permissionsObject.DASHBOARD_REPLACEMENTS_TOTAL_NUMBER_OF_REPLACEMENTS}>
                  <Replacements />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardGiftCodes}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_GIFT_CODES_TOTAL_NUMBER_OF_AVAILABLE_GIFT_CODES || authUser?.permissionsObject.DASHBOARD_GIFT_CODES_TOTAL_NUMBER_OF_SEND_GIFT_CODES_BY_CHAIN}>
                  <ImportedPlGiftCodes />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardTasksAndPoints}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_POINTS_AND_TASKS_NUMBER_OF_ACTIVE_TASKS || authUser?.permissionsObject.DASHBOARD_POINTS_AND_TASKS_NUMBER_OF_TASKS_COMPLETIONS || authUser?.permissionsObject.DASHBOARD_POINTS_AND_TASKS_POINTS_CATEGORY || authUser?.permissionsObject.DASHBOARD_POINTS_AND_TASKS_TOTAL_NUMBER_OF_POINTS}>
                  <TasksAndPoints />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardChains}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_CHAINS_ACTIVE_CHAINS || authUser?.permissionsObject.DASHBOARD_CHAINS_TOP_10_REGISTRATIONS || authUser?.permissionsObject.DASHBOARD_CHAINS_TOP_10_REPLACEMENTS || authUser?.permissionsObject.DASHBOARD_CHAINS_TOTAL_NUMBER_OF_CHAINS}>
                  <Chains />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardUsersByRole}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_USERS_USER_ROLES}>
                  <UsersByRolesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardActiveInactiveUsers}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_USERS_ACTIVE_USERS}>
                  <ActiveInactiveUsersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTopPosInRegistrations}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_POINTS_OF_SALE_TOP_10_REGISTRATIONS}>
                  <TopPosInRegistrationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardActiveInactivePos}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_POINTS_OF_SALE_ACTIVE_POS}>
                  <ActiveInactivePosPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTopPosInReplacements}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_POINTS_OF_SALE_TOP_10_REPLACEMENTS}>
                  <TopPosInReplacementsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTotalReplacements}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REPLACEMENTS_TOTAL_NUMBER_OF_REPLACEMENTS}>
                  <TotalReplacementsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTopReasonsForReplacements}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REPLACEMENTS_TOP_10_REASONS_FOR_REPLACEMENT}>
                  <TopReasonsForReplacementsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardPointsCategories}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_POINTS_AND_TASKS_POINTS_CATEGORY}>
                  <PointsCategoriesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTotalPoints}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_POINTS_AND_TASKS_TOTAL_NUMBER_OF_POINTS}>
                  <TotalPointsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardActiveInactiveChains}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_CHAINS_ACTIVE_CHAINS}>
                  <ActiveInactiveChainsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTopChainInRegistrations}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_CHAINS_TOP_10_REGISTRATIONS}>
                  <TopChainsInRegistrationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTopChainInReplacements}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_CHAINS_TOP_10_REPLACEMENTS}>
                  <TopChainsInReplacementsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTotalRegistrations}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_TOTAL_NUMBER_OF_REGISTRATIONS}>
                  <TotalRegistrationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardMethodOfVerification}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_METHOD_OF_VERIFICATION}>
                  <MethodOfVerificationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardReDOI}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_RE_DOI}>
                  <ReDOIPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardRepurchases}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_TOTAL_NUMBER_OF_RE_PURCHASES}>
                  <RepurchasesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardGenderTitle}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_CONSUMER_TITLE_GENDER}>
                  <GenderTitlePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardSplitOfTotalRegistrations}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_CONSUMER_AGE_GROUPS}>
                  <SplitOfTotalRegistrationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardSplitOfTotalRegistrationsByRegistrationAge}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_CONSUMER_AGE_GROUPS}>
                  <SplitOfTotalRegistrationsByRegistrationAgePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardPreviouslyOwnedDevice}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_PREVIOUSLY_OWNED_DEVICE}>
                  <PreviouslyOwnedDevicePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardPurchasedProducts}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_PURCHASED_PRODUCT}>
                  <PurchasedProductsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardRegistrationsByCities}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_REGISTRATIONS_TOP_10_CITIES}>
                  <RegistrationsByCitiesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardNumberOfActiveTasks}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_POINTS_AND_TASKS_NUMBER_OF_ACTIVE_TASKS}>
                  <NumberOfActiveTasksPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardNumberOfTaskCompletions}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_POINTS_AND_TASKS_NUMBER_OF_TASKS_COMPLETIONS}>
                  <NumberOfTaskCompletionsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTotalNumberOfAvailableGiftCodes}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_GIFT_CODES_TOTAL_NUMBER_OF_AVAILABLE_GIFT_CODES}>
                  <TotalNumberOfAvailableGiftCodesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTotalNumberOfSentGiftCodes}`}
              element={
                <ProtectedRoute isAllowed={authUser?.permissionsObject.DASHBOARD_GIFT_CODES_TOTAL_NUMBER_OF_SEND_GIFT_CODES_BY_CHAIN}>
                  <TotalNumberOfSentGiftCodesPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default Core;
