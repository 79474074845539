import * as Sentry from '@sentry/react';
import { httpClientIntegration } from '@sentry/integrations';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

const SENTRY_ENVIRONMENT = `${import.meta.env.VITE_APP_MARKET?.toLowerCase()}-${import.meta.env.VITE_APP_ENV?.toLowerCase()}`;
const SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  release: import.meta.env.SENTRY_RELEASE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [import.meta.env.VITE_APP_HTTP_LINK as string],
    }),
    httpClientIntegration(),
  ],
  // beforeBreadcrumb: excludeGraphQLFetch,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
  tracePropagationTargets: ['localhost', /api\..*\/graphql/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  sendDefaultPii: true,

  ignoreErrors: [
    'Unauthorized',
    'No refresh token found in cookie',
    'ForbiddenError',
    'jwt expired',
    'Unauthorized',
    'Point of Sell is not active',
    'User is not active',
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  // <StrictMode>
  <App />,
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
