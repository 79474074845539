import { AuthUserFragment, FeatureFlagObjectFragment, PermissionEnum, PermissionsObjectFragment, } from 'Apollo/graphql';

export const hasAuthUserPermission = (
  authUser?: AuthUserFragment | null,
  permission?: PermissionEnum | null | PermissionEnum[],
  flag?: keyof FeatureFlagObjectFragment | null,
): boolean => {
  const hasFeatureFlag = !flag || Boolean(authUser?.featureFlagsObject[flag]);
  if (!hasFeatureFlag) {
    return false;
  }
  if (authUser?.role === 'SUPER_ADMIN') return true;
  const permissionArray = !Array.isArray(permission) ? [permission,] : permission;
  const hasPermission = !permission || permissionArray.some((perm) => Boolean(authUser?.permissionsObject[perm as keyof PermissionsObjectFragment]));
  return hasPermission;
};