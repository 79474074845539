import { MutationResult, } from '@apollo/client/react/types/types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { VerifyGiftCodeMutation, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { CustomErrorMessage, } from 'Components/Errors/types';
import { FormInputText, FormSubmit, } from 'Components/Form';
import { useTranslation, } from 'react-i18next';

interface Props {
  verificationResult: MutationResult<VerifyGiftCodeMutation>;
  customError: CustomErrorMessage | null;
}

const View = ({ verificationResult, customError, }: Props): JSX.Element => {
  const { t, } = useTranslation('giftCodeSeller');

  return (
    <>
      <Typography variant="h1" sx={{ mb: 1.5, }}>
        {t('form.verificationTitle')}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3, }}>
        {t('form.verificationInfo')}
      </Typography>

      <Box mb={4}>
        <FormInputText autoFocus name="giftCode" label={t('form.giftCodeNumber')} disabled={verificationResult.loading} />
      </Box>

      <FormSubmit size="large" color="primary" sx={{ width: { xs: '100%', lg: 'unset', }, }}>
        {t('form.btnVerify')}
      </FormSubmit>
      <ErrorAlert sx={{ mt: 2, }} error={verificationResult.error} customError={customError} />
    </>
  );
};

export default View;
