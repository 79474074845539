import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { useTranslation } from 'react-i18next';
import ExportQrCodes from './ExportQrCodes';
import InputPos from './InputPos';

interface Props {
  onClose: () => void;
  showPosSelector: boolean;
}

const View = ({ onClose, showPosSelector }: Props): JSX.Element => {
  const { t } = useTranslation('registrationsAdmin');
  return (
    <ModalDrawerBody>
      <Grid container spacing={3}>
        {showPosSelector && (
          <Grid item xs={12}>
            <InputPos />
          </Grid>
        )}
        <ExportQrCodes />
      </Grid>
      <Box mt={4}>
        <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
          {t('common:actions.cancel')}
        </Button>
      </Box>
    </ModalDrawerBody>
  );
};

export default View;
