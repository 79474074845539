import { useTranslation, } from 'react-i18next';
import shallow from 'zustand/shallow';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AppContainer from 'Components/AppContainer';
import PlusIcon from 'Utils/svg/PlusIcon';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementAdmin/store/ReplacementStore';
import { useAuthUser, } from 'Tools/auth';
import Replacements from './Replacements';

const selectStoreData = (s: ReplacementStore) => ({
  openExportModal: s.openModalExportReplacement,
  openCreateModal: s.openModalCreateReplacement,
});

const ReplacementTable = (): JSX.Element => { 
  const { t, } = useTranslation('replacementAdmin');
  const { openExportModal, openCreateModal, } = useReplacementStore(selectStoreData, shallow);
  const authUser = useAuthUser();

  const canExport = authUser?.permissionsObject.ACCESS_ADMIN_EXPORT_DATA || authUser?.permissionsObject.UPDATE_REPLACEMENT;

  return (
    <AppContainer>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h1">{t('replacements.title')}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {canExport && (
              <Grid item>
                <Button variant="outlined" color="inherit" size="large" onClick={openExportModal}>
                  {t('replacements.btnExport')}
                </Button>
              </Grid>
            )}
            <Grid item>
              {authUser?.permissionsObject.CREATE_REPLACEMENT && (
                <Button variant="contained" color="primary" size="large" startIcon={<PlusIcon />} onClick={openCreateModal}>
                  {t('replacements.bntAdd')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Replacements />
    </AppContainer>
  );
};

export default ReplacementTable;
