import { useTranslation, } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import TableCellSortable from 'Components/Table/TableCellSortable';
import { ChainFragment, CustomerSortableFields, PointOfSellOptionFragment, useChainsQuery, } from 'Apollo/graphql';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import { areOptionsEqualById, getOptionFieldId, renderOptionById, } from 'Utils/helpers';
import { useSearchOptionsPos, } from 'Utils/options/useSearchOptionsPos';
import { useAuthUser, } from 'Tools/auth';
import TableCellText from 'Components/Table/TableCellText';

export const COL_WIDTHS = [
  { width: '50px', },
  { width: '250px', },
  { width: '150px', },
  { width: '100px', },
  { width: '130px', },
  { width: '150px', },
  { width: '120px', },
  { width: '140px', },
  { width: '45px', },
];

const PurchasesTableHeader = (): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const chainOptions = useChainsQuery();
  const posOptions = useSearchOptionsPos();
  const authUser = useAuthUser();
  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;
  const showSensitiveData = authUser?.permissionsObject.SHOW_CUSTOMER_DATA_IN_ADMIN;

  return (
    <TableHead>
      <TableRow>
        <TableCell style={COL_WIDTHS[0]} />
        {showSensitiveData && <TableCell style={COL_WIDTHS[1]}>{t('purchases.table.labelCustomerId')}</TableCell>}
        <TableCellSortable style={COL_WIDTHS[2]} name={CustomerSortableFields.CreatedAt}>
          {t('purchases.table.labelCreatedAt')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[2]}>{t('purchases.table.labelType')}</TableCell>
        <TableCell style={COL_WIDTHS[2]}>{t('purchases.table.labelPromotionId')}</TableCell>
        <TableCell style={COL_WIDTHS[2]}>{t('purchases.table.labelPromotionName')}</TableCell>
        <TableCell style={COL_WIDTHS[2]}>{t('purchases.table.labelProductId')}</TableCell>
        <TableCell style={COL_WIDTHS[2]}>{t('purchases.table.labelProductName')}</TableCell>
        <TableCell style={COL_WIDTHS[3]}>{t('purchases.table.quantity')}</TableCell>
        <TableCellSortable style={COL_WIDTHS[4]} name={CustomerSortableFields.PosId}>
          {t('purchases.table.labelPosId')}
        </TableCellSortable>
        {showChainIDs && (
          <TableCellSortable hidden={!showChainIDs} style={COL_WIDTHS[5]} name={CustomerSortableFields.ChainId}>
            {t('purchases.table.labelChainId')}
          </TableCellSortable>
        )}
        <TableCellSortable style={COL_WIDTHS[6]} name={CustomerSortableFields.AuthorId}>
          {t('purchases.table.labelAuthorId')}
        </TableCellSortable>
        <TableCellText style={COL_WIDTHS[7]}> {t('purchases.table.labelDeviceCode')}</TableCellText>
        <TableCell style={COL_WIDTHS[8]} />
      </TableRow>
      <TableRow>
        <TableCell />
        {showSensitiveData && (
          <TableCell>
            <TableFilterText name="email" />
          </TableCell>
        )}
        <TableCell />
        <TableCell />
        <TableCell>
          <TableFilterText name="promotionId" />
        </TableCell>
        <TableCell>
          <TableFilterText name="promotionName" />
        </TableCell>
        <TableCell>
          <TableFilterText name="assortmentId" />
        </TableCell>
        <TableCell>
          <TableFilterText name="assortmentName" />
        </TableCell>
        <TableCell />
        <TableCell>
          <TableFilterAutocomplete<PointOfSellOptionFragment>
            name="pos"
            options={posOptions.options}
            getOptionLabel={posOptions.getOptionLabel}
            isOptionEqualToValue={posOptions.isOptionEqualToValue}
            renderOption={renderOptionById(posOptions.getOptionLabel)}
            filterOptions={posOptions.filterOptions}
            loading={posOptions.loading}
            onInputChange={posOptions.onInputChange}
            noOptionsText={posOptions.noOptionsText}
          />
        </TableCell>
        {showChainIDs && (
          <TableCell hidden={!showChainIDs}>
            <TableFilterAutocomplete<ChainFragment>
              name="chain"
              options={chainOptions.data?.chains || []}
              getOptionLabel={getOptionFieldId}
              isOptionEqualToValue={areOptionsEqualById}
              renderOption={renderOptionById(getOptionFieldId)}
              hidden={!showChainIDs}
            />
          </TableCell>
        )}
        <TableCell>
          <TableFilterText name="author" />
        </TableCell>
        <TableCell>
          <TableFilterText name="deviceCode" />
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default PurchasesTableHeader;
