import { styled, } from '@mui/material/styles';
import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { UserDetailFragment, } from 'Apollo/graphql';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import CollapsibleItem from 'Modules/Users/components/CollapsibleItem';
import LinkButton from 'Components/LinkButton';
import { UserStore, useUserStore, } from 'Modules/Users/store/UserStore';
import { useAuthUser, } from 'Tools/auth';
import { valueOrEmpty, } from 'Utils/parsers';
import PointsSegment from './PointsSegment';
import FetchTaxData from './TaxSegment/FetchTaxData';

export const ColleagueWrapper = styled(Stack)(({ theme, }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

interface Props {
  user: UserDetailFragment;
  onClose: () => void;
}

const selectEraseModal = (s: UserStore) => s.openModalEraseAllUserData;
const selectRemoveModal = (s: UserStore) => s.openModalRemoveUser;
const selectChangePasswordModal = (s: UserStore) => s.openModalChangePassword;

const View = ({ user, onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('users');

  const authUser = useAuthUser();

  const openErase = useUserStore(selectEraseModal);
  const openRemove = useUserStore(selectRemoveModal);
  const openChangePassword = useUserStore(selectChangePasswordModal);

  const handleOpenErase = () => {
    openErase(user);
  };

  const handleOpenRemove = () => {
    openRemove(user);
  };

  const handleOpenChangePassword = () => {
    openChangePassword(user);
  };

  return (
    <ModalDrawerBody>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('user.labelUserId')}>{user.id}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('user.labelRole')}>{t(`common:options.userRoles.${user.role}`)}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('user.labelChainId')}>{valueOrEmpty(user.chainId)}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('user.labelPosId')}>{valueOrEmpty(user.posId)}</NotEditableTextClassic>
        </Grid>
        {authUser?.featureFlagsObject.FEATURE_FLAG_SHOW_POS_IDS_IN_USER_DASHBOARD && (
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('user.labelPosIds')}>{valueOrEmpty((user.posIds || []).join(', '))}</NotEditableTextClassic>
          </Grid>
        )}
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('user.labelUsername')}>{user.username}</NotEditableTextClassic>
        </Grid>
        {authUser?.permissionsObject.READ_USER_TAX_INFO && user && (
          <Grid item xs={12}>
            <FetchTaxData user={user}/>
          </Grid>
        )}
        <Grid item xs={12}>
          <CollapsibleItem label={t('user.labelUserData')}>
            <Stack spacing={2}>
              <NotEditableTextClassic label={t('user.labelFirstName')}>{valueOrEmpty(user.firstName)}</NotEditableTextClassic>
              <NotEditableTextClassic label={t('user.labelLastName')}>{valueOrEmpty(user.lastName)}</NotEditableTextClassic>
              <NotEditableTextClassic label={t('user.labelEmail')}>{valueOrEmpty(user.email)}</NotEditableTextClassic>
              <NotEditableTextClassic label={t('user.labelPhone')}>{valueOrEmpty(user.phoneNumberWithPrefix)}</NotEditableTextClassic>
            </Stack>
          </CollapsibleItem>
        </Grid>

        {user.pointOfSell && (
          <Grid item xs={12}>
            <CollapsibleItem label={t('user.labelPosData')}>
              <Stack spacing={2}>
                <NotEditableTextClassic label={t('pos:pointOfSell.labelPosId')}>{valueOrEmpty(user.pointOfSell?.id)}</NotEditableTextClassic>
                <NotEditableTextClassic label={t('pos:pointOfSell.labelChainId')}>{valueOrEmpty(user.chainId)}</NotEditableTextClassic>
                <NotEditableTextClassic label={t('pos:pointOfSell.labelChain')}>{valueOrEmpty(user.pointOfSell?.chain.name)}</NotEditableTextClassic>
                <NotEditableTextClassic label={t('pos:pointOfSell.labelStreet')}>{valueOrEmpty(user.pointOfSell?.street)}</NotEditableTextClassic>
                <NotEditableTextClassic label={t('pos:pointOfSell.labelNumber')}>
                  {valueOrEmpty(user.pointOfSell?.houseNumber)}
                </NotEditableTextClassic>
                <NotEditableTextClassic label={t('pos:pointOfSell.labelCity')}>{valueOrEmpty(user.pointOfSell?.city)}</NotEditableTextClassic>
                <NotEditableTextClassic label={t('pos:pointOfSell.labelPostalCode')}>
                  {valueOrEmpty(user.pointOfSell?.postalCode)}
                </NotEditableTextClassic>
              </Stack>
            </CollapsibleItem>
          </Grid>
        )}

        {user.collegues.length > 0 && (
          <Grid item xs={12}>
            <CollapsibleItem label={t('user.labelColleagues')}>
              {user.collegues.map((colleague) => (
                <ColleagueWrapper spacing={2} key={colleague.id}>
                  <NotEditableTextClassic label={t('user.labelFirstName')}>{colleague.firstName}</NotEditableTextClassic>
                  <NotEditableTextClassic label={t('user.labelLastName')}>{colleague.lastName}</NotEditableTextClassic>
                  <NotEditableTextClassic label={t('user.labelEmail')}>{colleague.email || '-'}</NotEditableTextClassic>
                  <NotEditableTextClassic label={t('user.labelPhone')}>{colleague.phonePrefix + colleague.phoneNumber || '-'}</NotEditableTextClassic>
                </ColleagueWrapper>
              ))}
            </CollapsibleItem>
          </Grid>
        )}
        <Grid item xs={12}>
          <PointsSegment userId={user.id} />
        </Grid>
      </Grid>

      <Box mt={2}>
        <Grid container spacing={3}>
          {authUser?.permissionsObject.MANAGE_USERS && (
            <Grid item xs={12}>
              <LinkButton type="button" variant="body1" onClick={handleOpenErase} color="secondary" disabled={false}>
                {t('detailUserModal.btnErase')}
              </LinkButton>
            </Grid>
          )}
          {authUser?.permissionsObject.MANAGE_USERS && (
            <Grid item xs={12}>
              <LinkButton type="button" variant="body1" onClick={handleOpenRemove} color="secondary" disabled={false}>
                {t('detailUserModal.btnDelete')}
              </LinkButton>
            </Grid>
          )}
          {authUser?.permissionsObject.MANAGE_USERS && (
            <Grid item xs={12}>
              <LinkButton type="button" variant="body1" onClick={handleOpenChangePassword} color="secondary" disabled={false}>
                {t('detailUserModal.btnChangePassword')}
              </LinkButton>
            </Grid>
          )}
          <Grid item>
            <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalDrawerBody>
  );
};

export default View;
