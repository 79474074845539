import { useEffect, useState, } from 'react';
import { useApolloClient, } from '@apollo/client';
import {
  RefreshTokenMutation,
  RefreshTokenDocument,
  CurrentUserQuery,
  CurrentUserDocument,
} from 'Apollo/graphql';
import { setAccessToken, } from '../store/authStore';
import { getOfflineRefreshToken, setOfflineRefreshToken, } from '../offlineRefreshTokenStore';

export const useAuthInitUser = (): boolean => {
  const [ loading, setLoading, ] = useState<boolean>(true);
  const client = useApolloClient();

  useEffect(() => {
    const initUser = async () => {
      const offlineRefreshToken = getOfflineRefreshToken();
      // skip refresh if no token
      if (!offlineRefreshToken) {
        setLoading(false);
        return;
      };

      // refresh
      try {
        setLoading(true);

        // TODO variables add offlineRefreshToken
        const responseRefresh = await client.mutate<RefreshTokenMutation, undefined>({
          mutation: RefreshTokenDocument,
          fetchPolicy: 'no-cache',
        });

        const {
          accessToken,
          logoutRefreshToken,
        }= responseRefresh?.data?.refreshToken || {};

        if (!accessToken) throw new Error('No access token');
        setAccessToken(accessToken);
        setOfflineRefreshToken(logoutRefreshToken || null);

        const responseUser = await client.query<CurrentUserQuery>({
          query: CurrentUserDocument,
          fetchPolicy: 'network-only',
        });

        const user = responseUser?.data?.currentUser;

        if (
          !user
          || (!user.permissionsObject.ACCESS_ADMIN_APP && !user.permissionsObject.ACCESS_SELLER_APP)
        ) throw new Error('invalid response: no user or no permission for admin / seller part of app');

        setLoading(false);
      } catch {
        setLoading(false);
        setAccessToken(null);
        setOfflineRefreshToken(null);
        // TOTO - remove current user from cache
      }
    };

    initUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading;
};

