import * as yup from 'yup';

export const ROUTES = {
  create: 'create',
  login: 'login',
  admin: 'admin',
  dashboard: 'dashboard',
  forgottenPassword: 'forgot-password',
  resetPassword: 'reset-password',
  assortment: 'assortment',
  pointsTransaction: 'pointsTransaction',
  promotions: 'promotions',
  registration: 'registration',
  shortRegistration: 'shortRegistration',
  users: 'users',
  pointsOfSell: 'points-of-sale',
  replacement: 'replacement',
  sellerSupport: 'sellerSupport',
  news: 'news',
  tasks: 'tasks',
  review: 'review',
  myPoints: 'myPoints',
  transactions: 'transactions',
  vouchers: 'emotions',
  myProfile: 'myProfile',
  editMyProfile: 'editMyProfile',
  deleteMyProfile: 'deleteMyProfile',
  giftCodeSeller: 'giftCodeSeller',
  czGiftCodeSeller: 'czGiftCodeSeller',
  editMyTaxInfo: 'editMyTaxInfo',
  prizeWheelXmas2023: 'prizeWheelXmas2023',
  dashboardUsers: 'users',
  dashboardPos: 'pos',
  dashboardRegistrations: 'registrations',
  dashboardReplacements: 'replacements',
  dashboardTasksAndPoints: 'tasks-and-points',
  dashboardChains: 'chains',
  dashboardGiftCodes: 'gift-codes',
  dashboardUsersByRole: 'users-by-roles',
  dashboardActiveInactiveUsers: 'total-users',
  dashboardTopPosInRegistrations: 'top-pos-in-registrations',
  dashboardTopPosInReplacements: 'top-pos-in-replacements',
  dashboardActiveInactivePos: 'total-pos',
  dashboardTotalReplacements: 'number-of-replacements',
  dashboardTopReasonsForReplacements: 'top-reasons-for-replacements',
  dashboardTotalPoints: 'number-of-points',
  dashboardNumberOfActiveTasks: 'number-of-active-tasks',
  dashboardNumberOfTaskCompletions: 'number-of-task-completions',
  dashboardPointsCategories: 'points-categories',
  dashboardActiveInactiveChains: 'total-chains',
  dashboardTopChainInRegistrations: 'top-chains-in-registrations',
  dashboardTopChainInReplacements: 'top-chains-in-replacements',
  dashboardTotalRegistrations: 'number-of-registrations',
  dashboardMethodOfVerification: 'method-of-verification',
  dashboardReDOI: 're-doi',
  dashboardRepurchases: 'number-of-repurchases',
  dashboardGenderTitle: 'gender-title',
  dashboardSplitOfTotalRegistrations: 'split-of-total-registrations',
  dashboardSplitOfTotalRegistrationsByRegistrationAge: 'split-of-total-registrations-by-registration-age',
  dashboardPreviouslyOwnedDevice: 'previously-owned-device',
  dashboardPurchasedProducts: 'purchased-products',
  dashboardRegistrationsByCities: 'registrations-by-cities',
  dashboardTotalNumberOfAvailableGiftCodes: 'total-number-of-available-gift-codes',
  dashboardTotalNumberOfSentGiftCodes: 'total-number-of-sent-gift-codes',
};

export enum LanguageCode {
  en = 'en',
  hu = 'hu',
  cz = 'cz',
  pl = 'pl',
}
export const REGEX_PHONE_NUMBER = /^[0-9]+$/g;

export const DEFAULT_LANG = LanguageCode.en;

export const IMAGE_MAX_SIZE = 1_000_000;
export const IMAGE_PHOTO_TASK_MAX_SIZE = 10_000_000;
export const VIDEO_MAX_SIZE = 30_000_000;
export const CSV_MAX_SIZE = 100_000_000;

export const SIDEBAR_WIDTH = 250;

export const APP_LANG = yup.mixed<LanguageCode>().oneOf(Object.values(LanguageCode)).required().validateSync(import.meta.env.VITE_APP_LANG);

export const PRIVACY_POLICY_LINK =
  APP_LANG === 'pl'
    ? yup.string().trim().required().validateSync(import.meta.env.VITE_APP_PRIVACY_POLICY_PL_LINK)
    : `/tos/${APP_LANG}/privacyPolicy.pdf`;
