import { yupResolver, } from '@hookform/resolvers/yup';
import { useVerifyGiftCodeMutation, } from 'Apollo/graphql';
import { CustomErrorMessage, } from 'Components/Errors/types';
import { mapBadFormDataError, } from 'Components/Form';
import { GiftCodeStoreStore, useGiftCodeStore, } from 'Modules/GiftCodeSeller/store/GiftCodeStore';
import { useState, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import shallow from 'zustand/shallow';
import { initPhaseBadFormDataFieldMapper, phaseInitFormValidationSchema, PhaseInitFormValues, } from './utils';
import View from './View';

const selectStoreData = (s: GiftCodeStoreStore) => ({
  completeInitPhase: s.completeInitPhase,
});

const PhaseInit = (): JSX.Element => {
  const { t, } = useTranslation('giftCodeSeller');
  const { completeInitPhase, } = useGiftCodeStore(selectStoreData, shallow);
  const [ errorInvalidVerificationCode, setErrorInvalidVerificationCode, ] = useState<CustomErrorMessage | null>(null);

  const methods = useForm<PhaseInitFormValues>({
    defaultValues: { giftCode: '', },
    resolver: yupResolver(phaseInitFormValidationSchema),
    mode: 'onSubmit',
  });

  const [verifyGiftCodeMutation,verificationResult, ] = useVerifyGiftCodeMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result.verifyGiftCode.success && result.verifyGiftCode.giftCode) {
        completeInitPhase(result.verifyGiftCode);
      }
    },
    onError: (responseError) => {
      setErrorInvalidVerificationCode({
        title: t('form.invalidGiftCodeError'),
        text: t('form.invalidGiftCodeError'),
      });
      mapBadFormDataError({
        apolloError: responseError,
        fieldMapper: initPhaseBadFormDataFieldMapper,
        methods,
      });
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await verifyGiftCodeMutation({
      variables: {
        giftCode: values.giftCode,
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View verificationResult={verificationResult} customError={errorInvalidVerificationCode}/>
      </form>
    </FormProvider>
  );
};

export default PhaseInit;
