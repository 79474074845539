import Typography from '@mui/material/Typography';
import { GiftCodeStoreStore, useGiftCodeStore, } from 'Modules/GiftCodeSeller/store/GiftCodeStore';
import { useTranslation, } from 'react-i18next';
import shallow from 'zustand/shallow';
import { GoBackButton, } from '../utils';

const selectStoreData = (s: GiftCodeStoreStore) => ({
  completeDetailPhase: s.completeDetailPhase,
  giftCode: s.giftCode,
  isImported: s.isImported,
  giftCodeStatus: s.status,
  resetStore: s.resetStore,
});

const PhaseComplete = (): JSX.Element => {
  const { giftCode, resetStore, } = useGiftCodeStore(selectStoreData, shallow);
  const { t, } = useTranslation('giftCodeSeller');
  return (
    <>
      {' '}
      <Typography variant="h1" sx={{ mb: 2, }}>
        {t('form.giftCodeApplied')}
      </Typography>
      <Typography sx={{ mb: 3, }}>{t("form.giftCodeAppliedInfo",{giftCode:giftCode.replace(/^(.{3})(.{3})(.*)$/, "$1 $2 $3"),})}</Typography>
      <Typography sx={{ mb: 3, }}>{new Date().toISOString()}</Typography>
      <GoBackButton goBackFunction={resetStore}/>
    </>
  );
};

export default PhaseComplete;
