import { UserSortInput, PaginationInput, UserFilterInput, } from 'Apollo/graphql';
import { UserStore, } from 'Modules/Users/store/UserStore';

export const mapVariables = (storeData: UserStore['tableUsers']): {
  filter?: UserFilterInput,
  pagination?: PaginationInput,
  sort?: UserSortInput,
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    username: storeData.filter.username 
      ? { contains: storeData.filter.username, }
      : undefined,
    posId: storeData.filter.pos
      ? { equals: storeData.filter.pos.id, }
      : undefined,
    chainId: storeData.filter.chain
    ? { equals: storeData.filter.chain.id, }
    : undefined,
    role: storeData.filter.role?.id
    ? { equals: storeData.filter.role?.id, }
    : undefined,
    firstName: storeData.filter.firstName
      ? { contains: storeData.filter.firstName, }
      : undefined,
    lastName: storeData.filter.lastName
      ? { contains: storeData.filter.lastName, }
      : undefined,
    phoneNumberWithPrefix: storeData.filter.phone
      ? { contains: storeData.filter.phone, }
      : undefined,
  },
  sort: storeData.data.sortField && storeData.data.sortOrder
    ? {
      field: storeData.data.sortField,
      order: storeData.data.sortOrder,
    } : undefined,
});
