import shallow from 'zustand/shallow';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { PurchasesStore, usePurchasesStore, } from 'Modules/RegistrationsAdmin/store/PurchasesStore';
import { usePurchasesQuery, } from 'Apollo/graphql';
import PurchasesTableRow from 'Modules/RegistrationsAdmin/pages/RegistrationAdminPage/PurchaseTable/PurchasesTableRow';
import { useAuthUser, } from 'Tools/auth';
import { mapVariablesPurchases, } from '../utils';
import PurchasesTableHeader from './PurchasesTableHeader';


const selectStoreData = (s: PurchasesStore) => ({
  tablePurchases: s.tablePurchases,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,  
  onDetail: s.openModalDetailPurchase,
});


const Purchases = (): JSX.Element => {
  const {
    onDetail,
    tablePurchases,
    changeFilter,
    changeSort,
    changeData,
  } = usePurchasesStore(selectStoreData, shallow);

  
  const { loading, error, data, } = usePurchasesQuery({
    variables: mapVariablesPurchases(tablePurchases),
    fetchPolicy: 'cache-and-network',
  });

  const authUser = useAuthUser();
  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;
  const showSensitiveData = authUser?.permissionsObject.SHOW_CUSTOMER_DATA_IN_ADMIN;
  const colSpan = 13 - +!showChainIDs - +!showSensitiveData;

  const filterIsAssortment = tablePurchases.filter.assortmentId || tablePurchases.filter.assortmentName;
  const filterIsPromotion = tablePurchases.filter.promotionId || tablePurchases.filter.promotionName;
  const filterIsAssortmentOrPromotion = !!(filterIsAssortment || filterIsPromotion);

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={colSpan}
      rows={data?.purchases.data.length || 0}
      loading={loading}
      error={error}
      limit={tablePurchases.data.limit}
      sortField={tablePurchases.data.sortField}
      sortOrder={tablePurchases.data.sortOrder}
      offset={tablePurchases.data.offset}
      count={data?.purchases.pagination.count}
      filter={tablePurchases.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <PurchasesTableHeader />
      <TableBody>
        {(data?.purchases.data || []).map((row) => (
          <PurchasesTableRow
            key={row.id}
            row={row}
            onDetail={onDetail}
            colSpan={colSpan}
            showChainIDs={showChainIDs}
            showSensitiveData={showSensitiveData}
            filterIsAssortmentOrPromotion={filterIsAssortmentOrPromotion}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default Purchases;
