import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CustomerWithPurchasesFragment, } from 'Apollo/graphql';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import LinkButton from 'Components/LinkButton';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import CollapsibleItem from 'Modules/RegistrationsAdmin/modals/ModalDetailRegistration/CollapsableItem';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationsAdmin/store/RegistrationStore';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';

interface Props {
  customer: CustomerWithPurchasesFragment;
  onClose: () => void;
}

const selectEraseModal = (s: RegistrationStore) => s.openModalEraseAllCustomerData;
const selectRemoveModal = (s: RegistrationStore) => s.openModalRemoveRegistration;

const View = ({ customer, onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const authUser = useAuthUser();

  const openErase = useRegistrationStore(selectEraseModal); // erase all user data
  const openRemove = useRegistrationStore(selectRemoveModal);

  const handleOpenErase = () => {
    openErase(customer);
  };

  const handleOpenRemove = () => {
    openRemove(customer);
  };
  const showSensitiveData = authUser?.permissionsObject.SHOW_CUSTOMER_DATA_IN_ADMIN ;

  return (
    <ModalDrawerBody>

      <Grid container spacing={3}>
      {showSensitiveData && <>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('detailRegistration.customerId')}>{customer ? customer.email : '-'}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('detailRegistration.customerInformation')}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {customer ? `${customer.firstName} ${customer.lastName}` : '-'}
              </Grid>
              <Grid item xs={12}>
                {customer.createdAt ? `${t('common:date.fullDateTime', { date: new Date(customer.createdAt), })}` : '-'}
              </Grid>
              <Grid item xs={12}>
                {customer ? `${customer.email}` : '-'}
              </Grid>
              <Grid item xs={12}>
                {customer ? `${customer.phoneNumberWithPrefix}` : '-'}
              </Grid>
            </Grid>
          </NotEditableTextClassic>
        </Grid>
      </>}
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('detailRegistration.posId')}>{customer ? customer.posId || '-' : '-'}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('detailRegistration.username')}>
            {customer?.author ? customer.author.username : '-'}
          </NotEditableTextClassic>
        </Grid>
      </Grid>
    
      <Box mt={2}>
        <Typography color="primary" variant="h4">
          {t('detailRegistration.orderHistory')}
        </Typography>
        {customer.purchases.map((purchase) => (
          <CollapsibleItem purchase={purchase} key={purchase.id} />
        ))}
      </Box>

      <Box mt={4}>
        <Grid container spacing={3}>
          {authUser?.permissionsObject.DELETE_CUSTOMER && (
            <Grid item xs={12}>
              <LinkButton type="button" variant="body1" onClick={handleOpenErase} color="secondary" disabled={false}>
                {t('detailRegistration.btnErase')}
              </LinkButton>
            </Grid>
          )}
          {authUser?.permissionsObject.DELETE_PURCHASE && (
            <Grid item xs={12}>
              <LinkButton type="button" variant="body1" onClick={handleOpenRemove} color="secondary" disabled={false}>
                {t('detailRegistration.btnDelete')}
              </LinkButton>
            </Grid>
          )}
          <Grid item>
            <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalDrawerBody>
  );
};

export default View;
