import { Trans, useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { RoleEnum, SearchCustomerByEmailQueryResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { FormInputText, FormSubmit, } from 'Components/Form';
import CheckOutlinedIcon from 'Utils/svg/CheckOutlinedIcon';
import CrossOutlinedIcon from 'Utils/svg/CrossOutlinedIcon';
import { SearchedCustomerByEmail, } from './types';
import AlreadyVerified from './AlreadyVerified';
import { useAuthUser } from 'Tools/auth';
import { useNavigate } from 'react-router-dom';

interface Props {
  searchedCustomerByEmail: SearchedCustomerByEmail | null,
  searchCustomerByEmailResult: SearchCustomerByEmailQueryResult,
  onPurchaseOrRegister: () => void,
}

const View = ({
  searchedCustomerByEmail,
  searchCustomerByEmailResult,
  onPurchaseOrRegister,
}: Props): JSX.Element => {
  const authUser = useAuthUser()
  const isCallCenter = authUser?.role === RoleEnum.CallCenterAgentCz;
  const { t, } = useTranslation('registrationSeller');
  const navigate = useNavigate();

  const renderStatusIcon = () => (
    isCallCenter ? (
      <>
        <CrossOutlinedIcon color="error" />
        <Typography variant="body1" sx={{ mb: 3, ml: 1 }} color="#E06765">
          <Trans i18nKey="registrationSeller:phaseInit.labelAlreadyRegisteredCallCenter" />
        </Typography>
      </>
    ) : (
      <>
        <CheckOutlinedIcon color="success" />
        <Typography variant="body1" sx={{ mb: 3, ml: 1 }} color="#4CB476">
          <AlreadyVerified />
        </Typography>
      </>
    )
  );

  const renderActionButton = () => {
    if (!searchedCustomerByEmail) {
      return (
        <FormSubmit size="large" color="primary" sx={{ width: { xs: '100%', lg: 'unset' } }}>
          {t('phaseInit.btnVerify')}
        </FormSubmit>
      );
    }
    if (isCallCenter && searchedCustomerByEmail.customer) {
      const handleDone = () => {
        navigate('/');
        // resetStore();
      };
      return (
        <Button
          color="primary"
          variant="contained"
          size="large"
          sx={{ width: { xs: '100%', lg: 'unset', }, }}
          onClick={handleDone}
        >
          {t('registrationComplete.btnDone')}
        </Button>
      )
    }

    return (
      <Button size="large" color="primary" sx={{ width: { xs: '100%', lg: 'unset' } }} onClick={onPurchaseOrRegister}>
        {searchedCustomerByEmail.isValid && searchedCustomerByEmail.customer
          ? t('phaseInit.btnPurchase')
          : t('phaseInit.btnRegister')}
      </Button>
    );
  };

  return (
    <>
      <Typography variant="h1" sx={{ mb: 1.5 }}>
        {t('phaseInit.title')}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        {!searchedCustomerByEmail || searchedCustomerByEmail?.isValid
          ? t('phaseInit.info')
          : t('phaseInit.infoRegisterCustomer')}
      </Typography>
      <Box mb={4}>
        <FormInputText
          autoFocus
          name="email"
          label={t('phaseInit.labelEmail')}
          disabled={searchCustomerByEmailResult.loading}
        />
      </Box>
      {searchedCustomerByEmail?.customer && (
        <Box mb={1} display="flex">
          {renderStatusIcon()}
        </Box>
      )}
      {renderActionButton()}
      <ErrorAlert sx={{ mt: 2 }} error={searchCustomerByEmailResult.error} />
    </>
  );
};

export default View;
