import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import ImageAsync from 'Components/ImageAsync';

const CardContainer = styled(Card)(({ theme, }) => ({
  borderRadius: theme.spacing(0.5),
  marginBottom: theme.spacing(4),
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(2, 1.5),
}));

const ImageContainer = styled('div')(({ theme, }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '75%',
  marginBottom: theme.spacing(2),

  '& > img': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

interface Props {
  title: React.ReactNode,
  imageSrc?: string,
  date: React.ReactNode,
  children: React.ReactNode,
}

const CardLayout = ({
  title,
  imageSrc,
  date,
  children,
}: Props): JSX.Element => {
  const { t, } = useTranslation();

  return (
    <CardContainer>
      <ImageContainer>
        <ImageAsync src={imageSrc} />
      </ImageContainer>
      <Typography variant="body1" color="gray" marginBottom={1}>
        {t('common:date.fullDate', { date: new Date(date as string), }) || '-'}
      </Typography>
      <Typography variant="h3" color="primary" marginBottom={1}>
        {title}
      </Typography>
      {children}
    </CardContainer>
  );
};

export default CardLayout;

