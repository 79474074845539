import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PurchaseDetailFragment, } from 'Apollo/graphql';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ProductCard from 'Components/ProductCard';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import { valueOrEmpty, } from 'Utils/parsers';

interface Props {
  purchase: PurchaseDetailFragment;
  onClose: () => void;
}

const View = ({ purchase, onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const authUser = useAuthUser();
  const productsExist = purchase.purchasedAssortments.length || purchase.purchasedPromotions.length;
  const showSensitiveData = authUser?.permissionsObject.SHOW_CUSTOMER_DATA_IN_ADMIN;
  const assortmentsWithoutPromotion = purchase.purchasedAssortments.filter((assortment) => !assortment.promotion) || [];
  return (
    <ModalDrawerBody>
      <Grid container spacing={3} sx={{ marginTop: 1, }}>
      {showSensitiveData &&
        <>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('detailRegistration.customerId')}>
              {purchase.customer ? purchase.customer.email : '-'}
            </NotEditableTextClassic>
          </Grid>
          {purchase.customer &&
            <Grid item xs={12}>
              <NotEditableTextClassic label={t('detailRegistration.customerInformation')}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {purchase.customer ? `${purchase.customer.firstName} ${purchase.customer.lastName}` : '-'}
                  </Grid>
                  <Grid item xs={12}>
                    {purchase?.customer?.dateOfBirth ? `${t('common:date.fullDateTime', { date: new Date(purchase.customer.dateOfBirth), })}` : '-'}
                  </Grid>
                  <Grid item xs={12}>
                    {purchase.customer ? `${purchase.customer.email}` : '-'}
                  </Grid>
                  <Grid item xs={12}>
                    {purchase.customer ? `${purchase.customer.phoneNumberWithPrefix}` : '-'}
                  </Grid>
                </Grid>
              </NotEditableTextClassic>
            </Grid>
          }
        </>
        }
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('detailRegistration.posId')}>{valueOrEmpty(purchase.posId)}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('detailRegistration.chainId')}>{valueOrEmpty(purchase.chainId)}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('detailRegistration.username')}>{valueOrEmpty(purchase.author.username)}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('detailRegistration.dateOfPurchase')}>
            {t('common:date.fullDateTime', { date: new Date(purchase.createdAt), })}
          </NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('detailRegistration.purchasedProducts')}>
            {productsExist ? (
              <>
                {purchase.purchasedPromotions?.map((product, index) => (
                  <>
                    <Divider sx={{ mt: '1rem', mb: '1rem', }} />
                    <ProductCard
                      
                      key={product.promotion.name}
                      title={product.promotion.name}
                      imageSrc={product.promotion.image?.publicUrl}
                      count={product.count}
                    />
                    {product.purchasedAssortments?.map((assortment) => (
                      <ProductCard
                        color="grey"
                        key={assortment.assortment.name}
                        title={assortment.assortment.name}
                        imageSrc={assortment.assortment.image?.publicUrl}
                        count={product.count}
                        deviceCode={assortment.deviceCode}
                      />
                    ))}
                  </>
                ))}
                <Divider sx={{ mt: '1rem', mb: '1rem', }} />

                {assortmentsWithoutPromotion.map((product) => (
                  <ProductCard
                    key={product.assortment.name}
                    title={product.assortment.name}
                    imageSrc={product.assortment.image?.publicUrl}
                    count={product.count}
                    deviceCode={product.deviceCode}
                  />
                ))}
              </>
            ) : (
              <Typography>{t('detailRegistration.noProducts')}</Typography>
            )}
          </NotEditableTextClassic>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Button size="large" color="inherit" variant="outlined" onClick={onClose}>
          {t('common:actions.close')}
        </Button>
      </Box>
    </ModalDrawerBody>
  );
};

export default View;
