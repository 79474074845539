import { ReactNode, ReactElement, } from 'react';
import { Navigate, Outlet, } from 'react-router-dom';
import { useAuthRedirectRoute, } from 'Tools/auth/hooks/useAuthRedirectRoute';

interface Props {
  isAllowed?: boolean,
  redirectToPath?: string,
  children?: ReactNode,
};

const ProtectedRoute = ({
  isAllowed,
  redirectToPath,
  children,
}: Props): JSX.Element => {
  const notAllowedRedirect = useAuthRedirectRoute(redirectToPath);

  if (!isAllowed) {
    return (
      <Navigate
        to={notAllowedRedirect}
        replace
      />
    );
  };

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  return children ? children as ReactElement : <Outlet />;
};

export default ProtectedRoute;
