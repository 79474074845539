import AppContainer from 'Components/AppContainer';
import { GiftCodePhase, GiftCodeStoreStore, useGiftCodeStore, } from 'Modules/GiftCodeSeller/store/GiftCodeStore';
import { ReactNode, useEffect, } from 'react';
import shallow from 'zustand/shallow';
import LayoutStepper from './LayoutStepper';
import PhaseComplete from './PhaseComplete';
import PhaseDetail from './PhaseDetail';
import PhaseInit from './PhaseInit';

const PHASE_COMPONENTS: { [key in Exclude<GiftCodePhase, 'init'>]: ReactNode } = {
  detail: <PhaseDetail />,
  complete: <PhaseComplete />,
};

const selectStoreData = (s: GiftCodeStoreStore) => ({
  phase: s.phase,
  resetStore: s.resetStore,
});

const GiftCodePage = () => {
  const { phase, resetStore, } = useGiftCodeStore(selectStoreData, shallow);

  useEffect(
    () => {
      return () => resetStore();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(() => window.scrollTo({ top: 0, }), [phase,]);

  return (
    <AppContainer maxWidth="sm">
      {phase === 'init' ? <PhaseInit /> : <LayoutStepper phase={phase}>{PHASE_COMPONENTS[phase]}</LayoutStepper>}
    </AppContainer>
  );
};

export default GiftCodePage;
