import { MouseEventHandler, } from 'react';
import { useTranslation, } from 'react-i18next';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import { AuthUserFragment, UserFragment, } from 'Apollo/graphql';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellText from 'Components/Table/TableCellText';
import { valueOrEmpty, } from 'Utils/parsers';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';

interface Props {
  row: UserFragment
  authUser: AuthUserFragment | null,
  onEdit: (user: UserFragment) => void,
  onRemove: (user: UserFragment) => void,
  onDetail: (user: UserFragment) => void,
  onErase: (user: UserFragment) => void,
  onChangePassword: (user: UserFragment) => void,
};

const UsersTableRow = ({
  row,
  authUser,
  onEdit,
  onRemove,
  onDetail,
  onErase,
  onChangePassword,
}: Props): JSX.Element => {
  const { t, } = useTranslation('users');
  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;
  const showPosIDs = authUser?.featureFlagsObject.FEATURE_FLAG_SHOW_POS_IDS_IN_USER_DASHBOARD;

  const handleRemove: MouseEventHandler<HTMLLIElement> = () => {
    onRemove(row);
  };

  const handleEdit: MouseEventHandler<HTMLLIElement> = () => {
    onEdit(row);
  };

  const handleDetail: MouseEventHandler<HTMLLIElement> = () => {
    onDetail(row);
  };

  const handleErase: MouseEventHandler<HTMLLIElement> = () => {
    onErase(row);
  };

  const handleChangePassword: MouseEventHandler<HTMLLIElement> = () => {
    onChangePassword(row);
  };

  return (
    <TableRow key={row.id}>
      <TableCellText>
        {valueOrEmpty(row.username)}
      </TableCellText>
      <TableCellText>
        {valueOrEmpty(row.posId)}
      </TableCellText>
      {showPosIDs && (
        <TableCellText>
          {valueOrEmpty(row.posIds?.join(', '))}
        </TableCellText>
      )}
      {showChainIDs &&
        <TableCellText>
          {valueOrEmpty(row.chainId)}
        </TableCellText>
      }
      <TableCellText>
        {t(`common:options.userRoles.${row.role}`, row.role)}
      </TableCellText>
      <TableCellText>
        {valueOrEmpty(row.firstName)}
      </TableCellText>
      <TableCellText>
        {valueOrEmpty(row.lastName)}
      </TableCellText>
      <TableCellText>
        {valueOrEmpty(row.phoneNumberWithPrefix)}
      </TableCellText>
      <TableCell>
        {row.wantsToBeDeleted ? <Chip label={t("common:options.yesNo.yes")} color='success' /> : <Chip color='error' label={t("common:options.yesNo.no")} />}
      </TableCell>
      <TableCellActions>
        <MenuItem
          onClick={handleDetail}
        >
          {t('users.table.btnDetail')}
        </MenuItem>
        {authUser?.permissionsObject.MANAGE_USERS && (
          <MenuItem
            onClick={handleEdit}
          >
            {t('users.table.btnEdit')}
          </MenuItem>
        )}
        {authUser?.permissionsObject.MANAGE_USERS && (
          <MenuItem
            onClick={handleRemove}
          >
            {t('users.table.btnRemove')}
          </MenuItem>
        )}
        {authUser?.permissionsObject.MANAGE_USERS && (
          <MenuItem
            onClick={handleErase}
          >
            {t('users.table.btnErase')}
          </MenuItem>
        )}
        {authUser?.permissionsObject.MANAGE_USERS && (
          <MenuItem
            onClick={handleChangePassword}
          >
            {t('users.table.btnChangePassword')}
          </MenuItem>
        )}

      </TableCellActions>
    </TableRow>
  );
};

export default UsersTableRow;
