import { QueryResult, } from '@apollo/client/react/types/types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Exact, GetQrCodeForBrandAmbassadorQuery, InputMaybe, } from 'Apollo/graphql';
import ClipboardCopy from 'Components/ClipboardButton';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { useTranslation, } from 'react-i18next';
import QRCode from 'react-qr-code';

interface Props {
  qrData: QueryResult<
    GetQrCodeForBrandAmbassadorQuery,
    Exact<{
      posId?: InputMaybe<string> | undefined;
    }>
  >;
}
const RenderQr = ({ qrData, }: Props): JSX.Element => {
  const { t, } = useTranslation();

  const { data, loading, error, } = qrData;

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  if (!data?.getQrCodeForBrandAmbassador) return <span>{t('common:errors.noQrCode')}</span>;
  if (error) return <ErrorAlert error={error} forceError />;
  return (
    <>
      <Grid item xs={12}>
        <QRCode value={data.getQrCodeForBrandAmbassador.url} />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ textDecoration: 'underline', wordBreak: 'break-all', }}>{data.getQrCodeForBrandAmbassador.url}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ClipboardCopy copyText={data.getQrCodeForBrandAmbassador.url} />
      </Grid>
    </>
  );
};

export default RenderQr;
