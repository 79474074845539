import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationsAdmin/store/RegistrationStore';
import { useTranslation, } from 'react-i18next';
import View from './View';

const selectClose = (s: RegistrationStore) => s.closeModalQrRegistration;
const selectOpen = (s: RegistrationStore) => s.modalQrRegistration;

const ModalQrCode = (): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const { isOpen, } = useRegistrationStore(selectOpen);
  const onClose = useRegistrationStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('qrModal.title')}
      </ModalDrawerTitle>
      <View
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalQrCode;
