import { GiftCodeStatusEnum, VerifyGiftCodeResultGql, } from 'Apollo/graphql';
import create from 'zustand';

export type GiftCodePhase = 'init' | 'detail' | 'complete';
type GiftCodeStoreState = {
  phase: GiftCodePhase;
  giftCode: string;
  isImported: boolean | null;
  status: GiftCodeStatusEnum | null;
  expirationDate: Date | null;
};

export type GiftCodeStoreStore = GiftCodeStoreState & {
  completeInitPhase: (result: VerifyGiftCodeResultGql) => void;
  completeDetailPhase: () => void;
  resetStore: () => void;
};

export const INIT_STATE: GiftCodeStoreState = {
  phase: 'init',
  giftCode: '',
  isImported: null,
  status: null,
  expirationDate: null,
};

export const useGiftCodeStore = create<GiftCodeStoreStore>((set) => ({
  ...INIT_STATE,
  completeInitPhase: (result) =>
    set((state) => ({
      ...INIT_STATE,
      phase: 'detail',
      giftCode: result.giftCode,
      isImported: result.isImported,
      status: result.status,
      expirationDate: result.expirationDate,
    })),
  completeDetailPhase: () =>
    set((state) => ({
      ...state,
      phase: 'complete',
    })),
  resetStore: () =>
    set((state) => ({
      ...INIT_STATE,
    })),
}));
