import { useMemo, } from 'react';
import { styled, } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { NewsArticleFragment, } from 'Apollo/graphql';
import CardLayout from 'Modules/NewsArticlesSeller/components/CardLayout';
import VideoPlayer from 'Components/VideoPlayer';
import { stringToRichText, } from 'Components/RichText/tools';
import RichText from 'Components/RichText';

const VideoContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',

  '& > video': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
}));

interface Props {
  newsArticle: NewsArticleFragment
}

const CardItem = ({
  newsArticle,
}: Props): JSX.Element => {
  const textDescendant = useMemo(
    () => stringToRichText(newsArticle.text),
    [ newsArticle.text, ],
  );

  return (
    <CardLayout
      title={newsArticle.title}
      date={newsArticle.createdAt}
      imageSrc={newsArticle.image?.publicUrl}
    >
      <Box mb={4}>
        <RichText
          initValue={textDescendant}
          readOnly
        />
      </Box>

      {newsArticle.video && (
        <VideoContainer>
          <VideoPlayer videoSrc={newsArticle.video?.publicUrl} />
        </VideoContainer>
      )}
    </CardLayout>
  );
};

export default CardItem;

