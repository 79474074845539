import { AuthUserFragment, RoleEnum, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/constants';

const REDIRECT_LOGIN = `/${ROUTES.login}`;
const REDIRECT_SELLER = `/`;
const REDIRECT_ADMIN = `/${ROUTES.admin}`;

export const getAuthRedirectRoute = (authUser: AuthUserFragment | null, defaultRedirectTo?: string): string => {
  if (defaultRedirectTo) return defaultRedirectTo;
  if (!authUser) return REDIRECT_LOGIN;
  // exception for pos manager
  if (authUser.role === RoleEnum.PosManager || authUser.role === RoleEnum.IndependentPosOwner || authUser.role === RoleEnum.CzIndependentPosOwner || authUser.role === RoleEnum.PlIndependentPosOwner) return REDIRECT_SELLER;
  if (authUser.permissionsObject.ACCESS_ADMIN_APP) return REDIRECT_ADMIN;
  return REDIRECT_SELLER;
};
