import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { useMemo, } from 'react';
import { AuthUserFragment, } from 'Apollo/graphql';
import { InformationVerificationFormValues, PromotionCategoryPurchaseObject, RegistrationPromotionValue, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { StoreAssortment, StorePromotionAssortment, } from 'Modules/RegistrationSeller/store/helpers';

export type ShowFields = {
  user: boolean, 
  pos: boolean,
  registerAsBrandAmbassador: boolean,
};
type InputShape = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any,
};

export const useInformationVerificationForm = (
  defaultValues: InformationVerificationFormValues,
  authUser: AuthUserFragment,
) => useMemo(
  () => {
    const initValues: InformationVerificationFormValues = {
      user: authUser?.isVirtualPosUser
        ? null
        : defaultValues.user || {
        id: authUser.id,
        username: authUser.username,
        firstName: authUser.firstName,
        lastName: authUser.lastName,
        email: authUser.email,
        role: authUser.role,
      },
      pos: authUser.pointOfSell
        ? defaultValues.pos || { id: authUser.pointOfSell.id, name: authUser.pointOfSell.name, chain: { id: authUser.pointOfSell.chain.id,}, }
        : defaultValues.pos || null,
      username: '',
    };
    const showFields: ShowFields = {
      user: authUser.isVirtualPosUser, 
      pos: !authUser.pointOfSell,
      registerAsBrandAmbassador: authUser.permissionsObject.BRAND_AMBASSADOR_REGISTRATION,
    };
    const inputShapes: InputShape = {};
    if (!showFields.registerAsBrandAmbassador && showFields.user) {
      inputShapes.user = yup.object()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED);
    }
    if (!showFields.registerAsBrandAmbassador && showFields.pos) {
      inputShapes.pos = yup.object()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED);
    }
    if (showFields.registerAsBrandAmbassador) {
      inputShapes.username = yup.string()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED);
    }

    return {
      initValues,
      showFields,
      formValidationSchema: (showFields.pos || showFields.user || showFields.registerAsBrandAmbassador)
        ? yup.object().shape(inputShapes)
        : undefined, 
    };
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [],
);

export const findDeviceCodeInLists = (devices: StoreAssortment[],promotions:StorePromotionAssortment[]):string | undefined => {
  const possibleCodeInDevices = devices.find((device)=> !!device.deviceCode)?.deviceCode;
  if (possibleCodeInDevices) return possibleCodeInDevices;

  const possibleCodeInPromotions = promotions.find((device)=> !!device.assortment.deviceCode)?.assortment.deviceCode;
  if (possibleCodeInPromotions) return possibleCodeInPromotions;
  return undefined;
};
// type ProductCardProps = {
//   key: string,
//   title: string,
//   imageSrc: string,
//   count: number,
// }
export const purchasedPromotionsToProductView = (promotionPurchases: PromotionCategoryPurchaseObject):RegistrationPromotionValue[]=> {
  const keysInPromotionPurchases = Object.keys(promotionPurchases);
  const promotionArrayByCategory:RegistrationPromotionValue[][] = [];
  for (let i = 0; i < keysInPromotionPurchases.length; i++) {
    promotionArrayByCategory.push(promotionPurchases[keysInPromotionPurchases[i]]);
  }
  const flatPromotionArrayByCategory = promotionArrayByCategory.flat();
  return flatPromotionArrayByCategory;
};
