import shallow from 'zustand/shallow';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import ProductCard from 'Components/ProductCard';
import { useAuthUser, } from 'Tools/auth';
import { CreateReplacementStore, useCreateReplacementStore, } from '../createReplacementStore';

const selectStoreData = (s: CreateReplacementStore) => ({
  customer: s.customer,
  replacement: s.replacement,
  resetStore: s.resetStore,
});

interface Props {
  onClose: () => void,
};

const PhaseTicketCompleted = ({
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const {
    customer,
    replacement,
    resetStore,
  } = useCreateReplacementStore(selectStoreData, shallow);

  const handleNewTicket = () => resetStore();

  const authUser = useAuthUser();
  const showSensitiveData = authUser?.permissionsObject.SHOW_CUSTOMER_DATA_IN_ADMIN;

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>
        {t('createReplacementModal.phaseTicketCompleted.title')}
      </ModalDrawerTitle>

      <ModalDrawerBody>
        <Typography
          variant="body1"
          sx={{ mb: 3, }}
        >
          {t('createReplacementModal.phaseTicketCompleted.info', { id: replacement?.id || 'id', })}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {replacement?.createdAt ? t('common:date.fullDateTime', { date: new Date(replacement.createdAt), }) : '-'}
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('createReplacementModal.phaseTicketCompleted.labelReplacementId')}
            >
              {replacement?.id || '-'}
            </NotEditableTextClassic>
          </Grid>
          {showSensitiveData && !!customer &&
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('createReplacementModal.phaseTicketCompleted.labelCustomerInformation')}
            >
              <div>
                {`${customer?.firstName}${customer?.lastName}`}
              </div>
              <div>
                {customer?.email}
              </div>
              <div>
                {`${customer?.phonePrefix}${customer?.phoneNumber}`}
              </div>
            </NotEditableTextClassic>
          </Grid>}
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('createReplacementModal.phaseTicketCompleted.labelCategory')}
            >
              {replacement?.category.name || '-'}
            </NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('createReplacementModal.phaseTicketCompleted.exchangedProducts')}
            >
              {(replacement?.replacedAssortments || []).map((a) => (
                <ProductCard
                  key={a.assortmentId}
                  title={a.assortment.name}
                  count={a.count}
                  imageSrc={a.assortment.image?.publicUrl}
                />
              ))}
            </NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('createReplacementModal.phaseTicketCompleted.labelReason')}
            >
              {replacement?.reason || '-'}
            </NotEditableTextClassic>
          </Grid>
        </Grid>
        <Box mt={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={onClose}
              >
                {t('createReplacementModal.phaseTicketCompleted.btnDone')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                color="inherit"
                onClick={handleNewTicket}
              >
                {t('createReplacementModal.phaseTicketCompleted.btnNewTicket')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </ModalDrawerBody>
    </>
  );
};

export default PhaseTicketCompleted;
