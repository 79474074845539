import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { RegistrationStore, useRegistrationStore } from 'Modules/RegistrationsAdmin/store/RegistrationStore';
import { useAuthUser } from 'Tools/auth';
import { useTranslation } from 'react-i18next';
import View from './View';

const selectClose = (s: RegistrationStore) => s.closeModalQrRegistrationBrandAmbassador;
const selectOpen = (s: RegistrationStore) => s.modalQrRegistrationBrandAmbassador;

const ModalQrCodeBrandAmbassador = (): JSX.Element => {
  const { t } = useTranslation('registrationsAdmin');
  const { isOpen } = useRegistrationStore(selectOpen);
  const onClose = useRegistrationStore(selectClose);
  const authUser = useAuthUser();
  const showPosSelector = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_BRAND_AMBASSADOR_WITH_POS_QR_REGISTRATION || false;

  return (
    <ModalDrawer onClose={onClose} open={isOpen}>
      <ModalDrawerTitle onClose={onClose}>{t('qrModal.createAmbassadorTitle')}</ModalDrawerTitle>
      <View onClose={onClose} showPosSelector={showPosSelector} />
    </ModalDrawer>
  );
};

export default ModalQrCodeBrandAmbassador;
