import AppContainer from 'Components/AppContainer';
import { Grid, Typography, } from '@mui/material';
import ActiveInactivePos from 'Components/Dashboard/POS/ActiveInactivePos';
import TopPosInReplacements from 'Components/Dashboard/POS/TopPosInReplacements';
import TopPosInRegistrations from 'Components/Dashboard/POS/TopPosInRegistrations';
import {
  DashboardLayoutItemName,
  useUserDashboardLayoutQuery,
} from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import { useTranslation, } from 'react-i18next';

const Pos = () => {
  const { data, } = useUserDashboardLayoutQuery();
  const authUser = useAuthUser();
  const { t, } = useTranslation('common');
  return (
    <AppContainer>
      <Typography variant="h1" marginBottom={2}>
        {t('dashboardSidebar.pos')}
      </Typography>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.permissionsObject.DASHBOARD_POINTS_OF_SALE_ACTIVE_POS && (
            <Grid item xs={12}>
              <ActiveInactivePos
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.PointsOfSaleActivePos,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.permissionsObject
            .DASHBOARD_POINTS_OF_SALE_TOP_10_REPLACEMENTS && (
            <Grid item xs={12}>
              <TopPosInReplacements
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.PointsOfSaleTop_10Replacements,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.permissionsObject
            .DASHBOARD_POINTS_OF_SALE_TOP_10_REGISTRATIONS && (
            <Grid item xs={12}>
              <TopPosInRegistrations
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.PointsOfSaleTop_10Registrations,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AppContainer>
  );
};

export default Pos;
