import Button from '@mui/material/Button';
import { useTranslation, } from 'react-i18next';

interface ButtonProps {
  goBackFunction: () => void;
}

export const GoBackButton = ({ goBackFunction, }: ButtonProps) => {
  const { t, } = useTranslation();
  return (
    <Button onClick={goBackFunction} size="large" sx={{ width: { xs: '100%', lg: 'unset', }, }} variant="outlined" color="primary">
      {t('common:actions.back')}
    </Button>
  );
};
