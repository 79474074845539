import jwtDecode from 'jwt-decode';
import { setAccessToken, getAccessToken, setOfflineRefreshToken, } from 'Tools/auth';
import { AuthTokenFragment, } from './graphql';

type DecodedToken = {
  exp: number;
};
export type RefreshLinkResult = AuthTokenFragment;

// TODO: Send Offline Refresh Token
export const fetchRefresh = (): Promise<Response> => {
  return fetch(import.meta.env.VITE_APP_HTTP_LINK as string, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', },
    credentials: 'include',
    body: JSON.stringify({
      query: `mutation refreshToken {
        refreshToken {
          accessToken
        }
      }`,
    }),
  });
};

export const handleRefreshCompleted = ({ accessToken, logoutRefreshToken, }: AuthTokenFragment): void => {
  if (accessToken) {
    setAccessToken(accessToken);
    setOfflineRefreshToken(logoutRefreshToken);
  };
};


export const handleRefreshError = (): void => {
  // TODO
  // - unauthorized - remove tokens
  // - other - nothing
};


export const isAccessTokenValidOrUndefined = (): boolean => {
  const token = getAccessToken();
  if (!token) {
    return true;
  }

  try {
    const { exp, } = jwtDecode(token) as DecodedToken;
    const expiredDate = (exp * 1000) - 2000;
    if (Date.now() >= expiredDate) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
};
