import { useMemo, } from 'react';
import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { UserCreateInput, ChainFragment, PointOfSellOptionFragment, RoleEnum, UserUpdateInput, UserFragment, CreateUserMutationVariables, AuthUserFragment, } from 'Apollo/graphql';
import { PhonePrefixOption, createPhonePrefixOption, } from 'Utils/options/useOptionsPhonePrefix';
import { UserRolesManageOption, } from 'Utils/options/useOptionsUserRolesManage';
import { REGEX_PHONE_NUMBER, } from 'Utils/constants';


export type UserCreateFormValues = {
  role: UserRolesManageOption | null,
  posId: PointOfSellOptionFragment | null,
  posIds: PointOfSellOptionFragment[],
  chainId: ChainFragment | null,
  username: string,
  password: string,
  firstName: string,
  lastName: string,
  email: string,
  phonePrefix: PhonePrefixOption | null,
  phoneNumber: string,
};

export type UserEditFormValues = {
  firstName: string,
  lastName: string,
  email: string,
  phonePrefix: PhonePrefixOption | null,
  phoneNumber: string,
  role: UserRolesManageOption | null,
  posId: PointOfSellOptionFragment | null,
  posIds: PointOfSellOptionFragment[],
  chainId: ChainFragment | null,
};

export const initUserCreateFormValues: UserCreateFormValues = {
  role: null,
  posId: null,
  posIds: [],
  chainId: null,
  username: '',
  password: '',
  firstName: '',
  lastName: '',
  email: '',
  phonePrefix: null,
  phoneNumber: '',
};

export const useInitUserValues = (user: UserFragment): UserEditFormValues => useMemo<UserEditFormValues>(
  () => ({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phonePrefix: createPhonePrefixOption(user.phonePrefix),
    phoneNumber: user.phoneNumber,
    role:  null,
    posId:  null,
    posIds: [],
    chainId: null,
  }),
  [ user, ],
);

export const userCreateFormValidationSchema = yup.object().shape({
  role: yup.object()
    .nullable()
    .required(INPUT_FORM_ERRORS.REQUIRED),
  posId: yup.object()
    .nullable()
    .when('role', {
      is: (role: UserRolesManageOption | null) => role?.requiresPosId,
      then: yup.object()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
  posIds: yup.array()
    .when([ 'role', '$authUser', ], {
      is: (role: UserRolesManageOption | null, authUser: AuthUserFragment | null) => (
        authUser?.featureFlagsObject.FEATURE_FLAG_SHOW_POS_IDS_IN_USER_DASHBOARD && role?.requiresPosIds
      ),
      then: yup.array()
        .required(INPUT_FORM_ERRORS.REQUIRED)
        .min(1, INPUT_FORM_ERRORS.REQUIRED),
    }),
  chainId: yup.object()
    .nullable()
    .when('role', {
      is: (role: UserRolesManageOption | null) => role?.requiresChainId,
      then: yup.object()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
  username: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  password: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  firstName: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  lastName: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  email: yup.string()
    .email(INPUT_FORM_ERRORS.EMAIL)
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  phonePrefix: yup.object()
    .nullable()
    .required(INPUT_FORM_ERRORS.REQUIRED),
  phoneNumber: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .matches(REGEX_PHONE_NUMBER, INPUT_FORM_ERRORS.PHONE)
    .max(25, INPUT_FORM_ERRORS.STRING_MAX),
});

export const userEditFormValidationSchema = yup.object().shape({
  firstName: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  lastName: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  email: yup.string()
    .email(INPUT_FORM_ERRORS.EMAIL)
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  phonePrefix: yup.object()
    .nullable()
    .required(INPUT_FORM_ERRORS.REQUIRED),
  phoneNumber: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .matches(REGEX_PHONE_NUMBER, INPUT_FORM_ERRORS.PHONE)
    .max(25, INPUT_FORM_ERRORS.STRING_MAX),
  posId: yup.object()
    .nullable()
    .when('role', {
      is: (role: UserRolesManageOption | null) => role?.requiresPosId,
      then: yup.object()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
  posIds: yup.array()
    .when([ 'role', '$authUser', ], {
      is: (role: UserRolesManageOption | null, authUser: AuthUserFragment | null) => (
        authUser?.featureFlagsObject.FEATURE_FLAG_SHOW_POS_IDS_IN_USER_DASHBOARD && role?.requiresPosIds
      ),
      then: yup.array()
        .required(INPUT_FORM_ERRORS.REQUIRED)
        .min(1, INPUT_FORM_ERRORS.REQUIRED),
    }),
  chainId: yup.object()
    .nullable()
    .when('role', {
      is: (role: UserRolesManageOption | null) => role?.requiresChainId,
      then: yup.object()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),

});

export const mapFormCreateToVariables = (values: UserCreateFormValues, authUser: AuthUserFragment | null): UserCreateInput => ({
  role: values.role?.id || '' as RoleEnum,
  posId: values.role?.requiresPosId
    ? values.posId?.id || ''
    : undefined,
  posIds: (authUser?.featureFlagsObject.FEATURE_FLAG_SHOW_POS_IDS_IN_USER_DASHBOARD && values.role?.requiresPosIds)
    ? values.posIds.map(p => p.id)
    : undefined,
  chainId: values.role?.requiresChainId
    ? values.chainId?.id || ''
    : undefined,
  username: values.username,
  password: values.password,
  firstName: values.firstName,
  lastName: values.lastName,
  email: values.email,
  phonePrefix: values.phonePrefix?.id || '',
  phoneNumber: values.phoneNumber,
});

export const mapFormEditToVariables = (values: UserEditFormValues): UserUpdateInput => ({
  firstName: values.firstName,
  lastName: values.lastName,
  email: values.email,
  phonePrefix: values.phonePrefix?.id || '',
  phoneNumber: values.phoneNumber,
  role: values.role?.id as RoleEnum,
  posId: values?.posId?.id || null,
  posIds: values?.posIds.map((value)=> value.id) || [],
  chainId: values?.chainId?.id || null,
});

type CreateBadFormDataFieldMapper = {
  [key in keyof CreateUserMutationVariables['userInput']]: undefined | keyof UserCreateFormValues
};

export const createBadFormDataFieldMapper: CreateBadFormDataFieldMapper = {
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  password: 'password',
  phonePrefix: 'phonePrefix',
  phoneNumber: 'phoneNumber',
  role: 'role',
  username: 'username',
  chainId: 'chainId',
  posId: 'posId',
};
