import { GiftCodeStatusEnum, useApplyGiftCodeMutation, } from 'Apollo/graphql';
import { GiftCodeStoreStore, useGiftCodeStore, } from 'Modules/GiftCodeSeller/store/GiftCodeStore';
import shallow from 'zustand/shallow';
import View from './View';

const selectStoreData = (s: GiftCodeStoreStore) => ({
  completeDetailPhase: s.completeDetailPhase,
  giftCode: s.giftCode,
  isImported: s.isImported,
  giftCodeStatus: s.status,
  expirationDate: s.expirationDate,
  resetStore: s.resetStore,
});

const PhaseDetail = (): JSX.Element => {
  const { completeDetailPhase, giftCode, isImported, giftCodeStatus,resetStore,expirationDate, } = useGiftCodeStore(selectStoreData, shallow);

  const [applyGiftCodeMutation,] = useApplyGiftCodeMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result.applyGiftCode.giftCode) {
        completeDetailPhase();
      }
    },
    onError: ()=> resetStore(),
  });

  const applyGiftCode = () =>
    applyGiftCodeMutation({
      variables: {
        giftCode,
        isImported: isImported as boolean,
      },
    });
  return <View expirationDate={expirationDate} giftCodeStatus={giftCodeStatus as GiftCodeStatusEnum} giftCode={giftCode} applyCode={applyGiftCode} resetStore={resetStore} />;
};

export default PhaseDetail;
