import ErrorAlert from 'Components/Errors/ErrorAlert';
import { NewsArticleDetailQuery, } from 'Apollo/graphql';
import SkeletonItem from 'Modules/NewsArticlesSeller/components/SkeletonItem';
import { ApolloError, } from '@apollo/client';
import CardItem from './CardItem';

interface Props {
  data?: NewsArticleDetailQuery,
  loading: boolean,
  error?: ApolloError,
}

const NewsArticleDetail = ({
  data,
  loading,
  error,
}: Props) => {

  if (loading) return (
    <SkeletonItem />
  );

  if (error || !data?.newsArticle) return (
    <ErrorAlert
      forceError
      error={error}
    />
  );

  return (
    <CardItem newsArticle={data.newsArticle} />
  );
};

export default NewsArticleDetail;
